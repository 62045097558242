angular.module('categories', ['ui.router', 'categories.list.html', 'categories.show.html', 'categories.create.html', 'confirm.modal.html','ngResource', 'ngTable', 'ui.bootstrap']);

angular.module('categories').config(['$stateProvider','$urlRouterProvider', function($stateProvider,$urlRouterProvider) {
    $stateProvider

    .state('categories', {
        url         : "/categories",
        parent: 'app.admin',
        views: {
            'content':{
                templateUrl : server+"/views/m/categories",
                controller  : 'categories.list',
            }
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'categorias'
        }
    })

    .state('categories_create', {
        url         : "/categories/create",
        parent: 'app.admin',
        views: {
            'content':{
                templateUrl : 'categories.create.html',
                controller  : 'categories.create',
            }
        },
        ncyBreadcrumb: {
            parent: 'categories',
            label: '{{category.name}}'
        }
    })

    .state('categories_show', {
        url         : "/categories/:id",
        parent: 'app.admin',
        views: {
            'content':{
                templateUrl : 'categories.show.html',
                controller  : 'categories.show',
            }
        },
        ncyBreadcrumb: {
            parent: 'categories',
            label: '{{category.name}}'
        }
    })
}]);

/**
 * List Controller
 */
angular.module('categories').
controller('categories.list',['$scope', '$http', '$auth', '$q', 'NgTableParams', '$category',function($scope, $http, $auth, $q, NgTableParams, $category) {
  $scope.tableParams = new NgTableParams({
    page  : 1,
    count : 10
  }, {
    filterDelay : 0,
    getData     : function(params) {
      return $category.query(params.url()).$promise.then(function(data) {
        params.total(data.total);
        return data.data;
      });
    }
  });
}]);

/**
 * Create Controller
 */
angular.module('categories').
controller('categories.create',['$scope', '$state', '$category',function($scope, $state, $category) {
  $scope.save = function(){
    $category.save($scope.category).$promise.then(function(data){
      $state.go('categories_show', {id: data.id});
    });
  }
}]);

/**
 * Show, Edit, Delete Controller
 */
angular.module('categories').
controller('categories.show',['$state', '$scope', '$stateParams', '$uibModal', '$category', function($state, $scope, $stateParams, $uibModal, $category){

  $scope.editing = false;

  $scope.toggleEdit = function(){
    $scope.editing = !$scope.editing;
  }

  $category.get({ id: $stateParams.id }).$promise.then(function(data) {
    $scope.category = data;
  }, function(error){
    if(error.status == 404){
      $state.go('notFound');
    }
  });

  $scope.confirmUpdate = function(){
    var modalInstance = $uibModal.open({
      animation   : true,
      templateUrl : 'confirm.modal.html',
      size        : 'sm',
      scope       : $scope,
      controller  : ['$scope', '$uibModalInstance', '$category', function($scope, $uibModalInstance, $category){

        $scope.title = 'Deseja atualizar esse dado?';
        $scope.cancel_text = 'Cancelar';
        $scope.success_text = 'Concluído';

        var category = $scope.$parent.category;

        $scope.confirm = function(){
          $category.update({id:category.id}, category).$promise.then(function(data){
            $uibModalInstance.dismiss('cancel');
            $scope.$parent.toggleEdit();
          })
        }

        $scope.cancel = function () {
          $uibModalInstance.dismiss('cancel');
        };

      }]
    });
  };

  $scope.confirmDelete = function(){
    var modalInstance = $uibModal.open({
      animation   : true,
      templateUrl : 'confirm.modal.html',
      size        : 'sm',
      scope       : $scope,
      controller  : ['$state', '$scope', '$uibModalInstance', '$category', function($state, $scope, $uibModalInstance, $category){

        $scope.title        = 'Deseja remover esse registro?';
        $scope.cancel_text  = 'Não';
        $scope.success_text = 'Sim';

        var category = $scope.$parent.category;

        $scope.confirm = function(){
          $category.delete({id:category.id}).$promise.then(function(data){
            $uibModalInstance.dismiss('cancel');
            $state.go('categories');
          })
        }

        $scope.cancel = function () {
          $uibModalInstance.dismiss('cancel');
        };

      }]
    });
  };

}]);


/**
 * Factory
 */
angular.module('categories').
factory("$category", [ '$resource', function($resource) {
  var resource = $resource(api+'category/:id', null, {
    'query' : { method:'GET', isArray:false },
    'update': { method:'PUT' }
  });
  return resource;
}]);

/**
 * Templates
 */
angular.module("categories.list.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put('categories.list.html',
    '<div class="container">'+
    ' <div class="actions btn-group" role="group" aria-label="...">'+
    '   <button type="button" ui-sref="categories_create" class="btn btn-outline btn-primary">Adicionar</button>'+
    ' </div>'+
    '   <table ng-table="tableParams" show-filter="true" class="table table-condensed table-striped table-pointer table-hover">'+
    '       <tr ui-sref="categories_show({id: row.id})" ng-repeat="row in $data track by row.id">'+
    '           <td style="width: 80px" title="\'#\'" filter="{id: \'text\'}" sortable="\'id\'">{{row.id}}</td>'+
    '           <td title="\'Nome\'" filter="{name: \'text\'}" sortable="\'name\'">{{row.name}}</td>'+
    '       </tr>'+
    '   </table>'+
    '</div>'
  );
}]);

angular.module("categories.show.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put('categories.show.html',
    '<div class="container">'+
    ' <div class="actions">'+
    '   <h1 class="title">Categoria</h1>'+
    '   <div class="pull-right">'+
    '       <div ng-if="editing" class="btn-group" role="group">'+
    '         <button ng-click="confirmDelete()" type="button" class="btn btn-outline btn-danger"><i class="fa fa-trash"></i> Remover</button>'+
    '       </div>'+
    '      <div class="btn-group" role="group">'+
    '         <button ng-click="toggleEdit()" ng-if="!editing" type="button" class="btn btn-outline btn-default">Editar</button>'+
    '         <button ng-click="confirmUpdate()" ng-if="editing" type="button" class="btn btn-outline btn-primary"><strong>Concluído</strong></button>'+
    '         <button ng-click="toggleEdit()" ng-if="editing" type="button" class="btn btn-outline btn-default">Cancelar</button>'+
    '       </div>'+
    '   </div>'+
    ' </div>'+
    '    <form name="form" class="">'+
    '      <div class="row">'+
    '        <div class="col-md-12">'+
    '          <div class="form-group">'+
    '            <label class="control-label">Nome</label>'+
    '            <div><input type="text" ng-model="category.name" ng-disabled="!editing" class="form-control" placeholder="Name"></div>'+
    '          </div>'+
    '        </div>'+
    '      </div>'+
    '    </form>'+
    '</div>'
  );
}]);

angular.module("categories.create.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put('categories.create.html',
    '<div class="container">'+
    ' <div class="actions">'+
    '   <h1 class="title">Adicionar</h1>'+
    '   <div class="btn-group pull-right" role="group">'+
    '      <button ng-click="save()" ng-disabled="form.$invalid" type="button" class="btn btn-outline btn-primary"><strong>Salvar</strong></button>'+
    '   </div>'+
    ' </div>'+
    '    <form name="form" class="">'+
    '      <div class="row">'+
    '        <div class="col-md-12">'+
    '          <div class="form-group">'+
    '            <label class="control-label">Nome</label>'+
    '            <div><input type="text" ng-model="category.name" required class="form-control" placeholder="Name"></div>'+
    '          </div>'+
    '        </div>'+
    '      </div>'+
    '    </form>'+
    '</div>'
  );
}]);


angular.module("confirm.modal.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put('confirm.modal.html',
    '<div class="modal-body text-center">'+
    ' {{title}}'+
    '</div>'+
    '<div class="modal-footer">'+
    '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>'+
    '    <button class="btn btn-outline btn-success" type="button" ng-click="confirm()">{{success_text}}</button>'+
    '</div>'
  );
}]);
