angular.module('users', ['ui.router','confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap', 'toggle-switch', 'angularjs-dropdown-multiselect', 'ui.utils.masks']);

angular.module('users').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

    $stateProvider
    .state('users', {
        url         : "/users",
        parent: 'app.admin',
        views: {
            'content':{
                templateUrl : server+"/views/m/users",
                controller  : 'user.list',
            }
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Usuários'
        }
    })

    .state('users_create', {
        url         : "/users/create",
        parent: 'app.admin',
        views: {
            'content':{
                templateUrl : server+"/views/m/users/create",
                controller  : 'user.create',
            }
        },
        ncyBreadcrumb: {
            parent: 'users',
            label: 'Adicionar'
        }
    })

    .state('users_show', {
        url         : "/users/:id",
        parent: 'app.admin',
        views: {
            'content':{
                templateUrl : server+"/views/m/users/show",
                controller  : 'user.show',
            }
        },
        ncyBreadcrumb: {
            parent: 'users',
            label: '{{user.data.name}}'
        }
    })

}]);

/**
 * List Controller
 */
angular.module('users').
controller('user.list', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$users', function($scope, $http, $auth, $q, NgTableParams, $users) {
    $scope.tableParams = new NgTableParams({
        page: 1,
        count: 10
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $users.query(params.url()).$promise.then(function(data) {
                params.total(data.total);
                return data.data;
            });
        }
    });
}]);

/**
 * Create Controller
 */
angular.module('users').
controller('user.create', ['$scope', '$state', '$users', 'NgTableParams', '$role', function($scope, $state, $users, NgTableParams, $role) {

    $scope.user = {
        status: '1',
        permissions: {}
    };


    $scope.validate = function()
    {
        var permissions = false;
        angular.forEach($scope.user.permissions, function(value, key){
            if (value === true)
                permissions = true;
        });

        if($scope.user.password == $scope.user.confirmation && permissions){
            return false;
        }
        return true;
    }

    $scope.tableParams = new NgTableParams({
        page: 1,
        count: 10
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $role.query(params.url()).$promise.then(function(data) {
                params.total(data.total);
                return data.data;
            });
        }
    });

    $scope.save = function() {
        $users.save($scope.user).$promise.then(function(data) {
            $state.go('users_show', {
                id: data.id
            });
        });
    }
}]);

/**
 * Show, Edit, Delete Controller
 */
angular.module('users').
controller('user.show', ['$state', '$scope', '$stateParams', '$uibModal', '$users', '$role', 'NgTableParams', function($state, $scope, $stateParams, $uibModal, $users, $role, NgTableParams) {

    $scope.editing = false;

    $scope.toggleEdit = function() {
        $scope.editing = !$scope.editing;
    }

    $scope.user = {
        status: '1',
        permissions: {}
    };

    $scope.validate = function()
    {
        var permissions = false;
        angular.forEach($scope.user.permissions, function(value, key){
            if (value === true)
                permissions = true;
        });

        if($scope.user.password == $scope.user.confirmation && permissions){
            return false;
        }
        return true;
    }

    $scope.tableParams = new NgTableParams({
        page: 1,
        count: 10,
        user: $stateParams.id
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $role.query(params.url()).$promise.then(function(data) {
                params.total(data.total);
                return data.data;
            });
        }
    });

    $users.get({
        id: $stateParams.id
    }).$promise.then(function(data) {
        data.status = data.status ? '1' :'0';
        $scope.user      = data;
    }, function(error) {
        if (error.status == 404) {
            $state.go('notFound');
        }
    });

    $scope.confirmUpdate = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$users', function($scope, $uibModalInstance, $users) {

                $scope.title = 'Confirma atualização?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Concluído';

                var user = $scope.$parent.user;

                $scope.confirm = function() {
                    $users.update({
                        id: user.id
                    }, user).$promise.then(function(data) {
                        $uibModalInstance.dismiss('cancel');
                        $scope.$parent.toggleEdit();
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

    $scope.confirmDelete = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$users', function($state, $scope, $uibModalInstance, $users) {

                $scope.title = 'Deseja remover esse registro?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                var user = $scope.$parent.user;

                $scope.confirm = function() {
                    $users.delete({id:user.id}).$promise.then(function(data){
                        $uibModalInstance.dismiss('cancel');
                        $state.go('users');
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

}]);


/**
 * Factory
 */
angular.module('users').
factory("$users",[ '$resource', '$q', '$http', '$filter', function($resource,$q,$http, $filter) {
    var resource = $resource(api + 'user/:id', null, {
        'query': {
            method: 'GET',
            isArray: false,
            transformResponse: function (response) {
                response = JSON.parse(response);
                _.each(response.data, function(data, key){
                    response.data[key].status = data.status ? 'Ativo':'Inativo';
                });
                return response;
            }
        },
        'update': {
            method: 'PUT'
        }
    });

    resource.getUsersbyClientId = function(client_id,params){
        console.log(params.filter());
        var deferred = $q.defer();

        $http.get(api+'user/list?count=3000&finish_registration=1&client_id=' + client_id).then(function(response){
           console.log(response);
           var filterObj = params.filter(),
           filteredData = $filter('filter')(response.data, filterObj);
           console.log(filteredData);

           
            deferred.resolve(filteredData);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;

    }

    resource.getTags = function(id){
        var deferred = $q.defer();
        $http.get(api+'users/'+id+'/get_tags').then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    return resource;
}]);

angular.module('users').
factory("$role", ['$resource', function($resource) {
    var resource = $resource(api + 'role/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        }
    });
    return resource;
}]);
