app.directive("detectScroll", ['$window', function ($window) {
    return function(scope, element, attrs) {

        angular.element($window).bind("scroll", function() {
            if (this.pageYOffset >= this.innerHeight-62) {
                scope.hasScrolled = true;
                console.log('Scrolled below header.');
            } else {
                scope.hasScrolled = false;
                console.log('Header is in view.');
            }
            scope.$apply();
        });
    };
}]);
