
var auth = angular.module('auth', ['satellizer', 'ngStorage', 'ngToast']);

auth.config(['$stateProvider', '$urlRouterProvider','ngToastProvider', function($stateProvider, $urlRouterProvider, ngToast) {

    // Now set up the states
    $stateProvider
    .state('auth', {
        url: "/auth",
        parent: 'app.public',
        views: {
            'content':{
                templateUrl: server + "/views/public/auth",
                controller: 'auth'
            }
        }
    })

}]);

auth.controller('auth', ['$rootScope', '$scope', '$http', '$auth', '$state', '$localStorage', '$interpolate', '$compile', '$uibModal', 'ngToast',
function($rootScope, $scope, $http, $auth, $state, $localStorage, $interpolate, $compile, $uibModal, ngToast) {

    /**0
     * 
     * Check if user is authenticated
     */
    $scope.isAuthenticated = $auth.isAuthenticated();
    $scope.user = $localStorage.user;
    $scope.loading = false;
    var iti;

    // if( $scope.isAuthenticated ){
    //     $rootScope.$broadcast('auth:login-success');
    // }

    /**
     * Store credentials
     */
    $scope.credentials = {
        remember: true,
        loginIn: true
    };

    /**
     * Authenticate user
     * @param string provider
     * @return bool
     */
    $scope.authenticate = function(provider) {
        $auth.authenticate(provider)
            .then(function(response) {
                $rootScope.$broadcast('auth:login-success');
            })
            .catch(function(response) {
                console.error(response);
            });
    };

  
    $scope.logout = function() {
        $auth.logout().then(function() {
            $rootScope.$broadcast('auth:logout-success');
        });
    }
 
    $scope.authenticatePhone = function(wpp) {
        
        $scope.loading = true;
        $scope.showSMSCheck = false;
        
        var credentials={}
        credentials.phone_number = iti.getNumber();
        credentials.remember = true;
        credentials.loginIn = true;
        credentials.wpp = wpp;

        console.log(credentials);

        $http.post(api+'auth/phone', credentials).then(function(response){
           $scope.loading = false;
           $scope.confirmSMSCode();
        }).catch(function(error){
            
            var message = "Erro: ";

            if(error.data.message == 'register_phone_error.')
                message += " Telefone já registrado.";

            if(error.data.message == 'phone_not_registered.')
                message += " Verifique o número e tente novamente.";

            ngToast.danger({
                content: message,
                dismissOnTimeout: true,
                timeout: 5000
            });
            $scope.loading = false;
        });
    };



    $scope.confirmSMSCode = function(){
        $scope.showSMSCheck = true;
        $scope.checkNumber = function(){
            $scope.loading = true;
            angular.element('#form').addClass("hidden");
            $http.post(api+'auth/validatePhone', $scope.credentials).then(function(response){
                iti.destroy();
                $auth.setToken(response.data.token);
                $rootScope.$broadcast('auth:login-success');

            }).catch(function(error){
                console.log(error);
                $scope.loading = false;
                var message = "Código inválido";
                ngToast.danger({
                    content: message,
                    dismissOnTimeout: true,
                    timeout: 5000
                });
            });

        }
    }
    
setTimeout(function(){

    var input = document.getElementById("tel");
    iti = intlTelInput(input, {
        initialCountry: "br",
         utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@18.1.1/build/js/utils.js",
    }); 
}, 500);

}]);

auth.directive('compile', ['$compile', function($compile) {
    return function(scope, element, attrs) {
        scope.$watch(
            function(scope) {
                // watch the 'compile' expression for changes
                return scope.$eval(attrs.compile);
            },
            function(value) {
                // when the 'compile' expression changes
                // assign it into the current DOM
                element.html(value);

                // compile the new DOM and link it to the current
                // scope.
                // NOTE: we only compile .childNodes so that
                // we don't get into infinite loop compiling ourselves
                $compile(element.contents())(scope);
            }
        );
    };
}]);

auth.directive('hoverClass', function () {
    return {
        restrict: 'A',
        scope: {
            hoverClass: '@'
        },
        link: function (scope, element) {
            element.on('mouseenter', function() {
                element.addClass(scope.hoverClass);
            });
            element.on('mouseleave', function() {
                element.removeClass(scope.hoverClass);
            });
        }
    };
});
