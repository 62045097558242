angular.module('support', ['ui.router','confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap']);

angular.module('support').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

    $stateProvider
    .state('support', {
        url         : "/support",
        parent: 'app.admin',
        views: {
            'content':{
                templateUrl : server+"/views/m/support/index",
                controller  : 'support.panel',
            }
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Suporte & Feedback'
        }
    })
    .state('support_requests', {
        url         : "/support/requests",
        parent: 'app.admin',
        views: {
            'content':{
                templateUrl : server+"/views/m/support/support_requests",
                controller  : 'support.requests',
            }
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Solicitações de Suporte'
        }
    })
    .state('view_request', {
        url         : "/support/requests/:id",
        parent: 'app.admin',
        views: {
            'content':{
                templateUrl : server+"/views/m/support/view_request",
                controller  : 'support.view_request',
            }
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Solicitação de Suporte'
        }
    })
    .state('feedbacks', {
        url         : "/support/feedbacks",
        parent: 'app.admin',
        views: {
            'content':{
                templateUrl : server+"/views/m/support/feedbacks",
                controller  : 'support.feedbacks',
            }
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Feedbacks'
        }
    }) 
    .state('faq', {
        url         : "/support/faq",
        parent: 'app.admin',
        views: {
            'content':{
                templateUrl : server+"/views/m/support/faq",
                controller  : 'support.faq',
            }
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'FAQ'
        }
    })
    .state('view_faq', {
        url         : "/support/faq/:id",
        parent: 'app.admin',
        views: {
            'content':{
                templateUrl : server+"/views/m/support/view_faq",
                controller  : 'support.view_faq',
            }
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'FAQ'
        }
    })


}]);

angular.module('support').
 controller('support.panel', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$support','$stateParams', function($scope, $http, $auth, $q, NgTableParams, $support,$stateParams) {
   $scope.averageRating = 0;
   $support.getFeedbacks().then(function(data) {
    for(var i = 0; i < data.data.length; i++){
       
        $scope.averageRating += parseFloat(data.data[i].rating);
    }
    $scope.averageRating = ($scope.averageRating / data.data.length).toFixed(1);
    console.log($scope.averageRating);   

    });
    $support.getUnresolvedSupportRequests().then(function(data) {
        console.log(data);
        $scope.unresolvedSupportRequests = data.length;
    });
 }]);

angular.module('support').
 controller('support.requests', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$support','$stateParams', function($scope, $http, $auth, $q, NgTableParams, $support,$stateParams) {
    $scope.tableParams = new NgTableParams({
        page: 1,
        count: 10
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $support.query(params.url()).$promise.then(function(data) {
                params.total(data.total);
                return data.data;
            });
        }
    });
 }]);

 angular.module('support').
 controller('support.view_request', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$support','$stateParams', function($scope, $http, $auth, $q, NgTableParams, $support,$stateParams) {
    $scope.request={};
    $support.getRequest($stateParams.id).then(function(data){
        $scope.request=data;
    });

    $scope.save = function(){
        $support.update({id: $scope.request.id}, $scope.request).$promise.then(function(data){
            console.log(data);
        });
     }


   
 }]);

 angular.module('support').
 controller('support.feedbacks', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$feedbacks','$stateParams', function($scope, $http, $auth, $q, NgTableParams, $feedbacks,$stateParams) {
    $scope.tableParams = new NgTableParams({
        page: 1,
        count: 10
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $feedbacks.query(params.url()).$promise.then(function(data) {
                params.total(data.total);
                return data.data;
            });
        }
    });
 }]);

 angular.module('support').
 controller('support.faq', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$support','$stateParams', function($scope, $http, $auth, $q, NgTableParams, $support,$stateParams) {
    $scope.tableParams = new NgTableParams({
        page: 1,
        count: 10
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $support.getFaq(params.url()).then(function(data) {
                params.total(data.total);
                console.log(data);
                return data;
            });
        }
    });
 }]);

 angular.module('support').
 controller('support.view_faq', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$support','$stateParams', function($scope, $http, $auth, $q, NgTableParams, $support,$stateParams) {
   console.log("view_faq");
    $scope.faq={};
    $support.getFaqById($stateParams.id).then(function(data){
        $scope.faq=data;
    });
 }]);

 angular.module('support').
factory("$feedbacks", [ '$resource', '$http','$q',function($resource, $http, $q) {
    var resource = $resource(api+'userFeedback', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        }
    })
    return resource;
}]);

 angular.module('support').
factory("$support", [ '$resource', '$http','$q',function($resource, $http, $q) {
    var resource = $resource(api + 'supportRequest/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        },
    });

   

    resource.getRequest = function(id){
        var deferred = $q.defer();
        $http.get(api+'supportRequest/'+id).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }


    resource.getSupportRequests = function(){
        var deferred = $q.defer();
        $http.get(api+'supportRequest').then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }
    resource.getUnresolvedSupportRequests = function(){
        var deferred = $q.defer();
        $http.get(api+'supportRequest/unresolved/requests').then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.getFeedbacks = function(){
        var deferred = $q.defer();
        $http.get(api+'userFeedback').then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.getFaq = function(){
        var deferred = $q.defer();
        $http.get(api+'faq').then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }
    resource.getFaqById = function(id){
        var deferred = $q.defer();
        $http.get(api+'faq/'+id).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }
    
    return resource;
}]);



