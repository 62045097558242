angular.module('device', ['ui.router','confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap', 'toggle-switch']);

angular.module('device').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

    $stateProvider

    .state('device', {
        url         : "/device",
        parent: 'app.admin',
        views: {
            'content':{
                templateUrl : server+"/views/m/device",
                controller  : 'device.list',
            }
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Dispositivos'
        }
    })

    .state('device_create', {
        url         : "/device/create",
        parent: 'app.admin',
        views: {
            'content':{
                templateUrl : server+"/views/m/device/create",
                controller  : 'device.create',
            }
        },
        ncyBreadcrumb: {
            parent: 'device',
            label: 'Adicionar'
        }
    })

    .state('device_show', {
        url         : "/device/:id",
        parent: 'app.admin',
        views: {
            'content':{
                templateUrl : server+"/views/m/device/show",
                controller  : 'device.show',
            }
        },
        ncyBreadcrumb: {
            parent: 'device',
            label: '{{device.hash}}'
        }
    })

}]);

/**
 * List Controller
 */
angular.module('device').
controller('device.list', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$device', function($scope, $http, $auth, $q, NgTableParams, $device) {
    $device.dashboard().then(function(data){
        $scope.offline_devices = Math.round(data.offline*100/(data.offline+data.online));
        $scope.online_devices = Math.round(data.online*100/(data.offline+data.online));
        $scope.total_devices = data.offline+data.online;
        console.log(data);
        $scope.device_data = [{total:$scope.offline_devices, name: "Offline"},{total:$scope.online_devices,name: "Online"}];
    })
    
    $scope.tableParams = new NgTableParams({
        page: 1,
        count: 10
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $device.query(params.url()).$promise.then(function(data) {
                params.total(data.total);
                return data.data;
            });
        }
    });
}]);


/**
 * Create Controller
 */
angular.module('device').
controller('device.create', ['$scope', '$state', '$device', '$device_model', function($scope, $state, $device, $device_model) {

    $device_model.query().$promise.then(function(response){
        $scope.models = response.data;
    });

    $scope.save = function() {
        $device.save($scope.device).$promise.then(function(data) {
            $state.go('device_show', {
                id: data.id
            });
        });
    }
}]);

/**
 * Show, Edit, Delete Controller
 */
angular.module('device').
controller('device.show', ['$state', '$scope', '$stateParams', '$interval','$uibModal', '$device', '$deviceMessages','$device_model', '$task', 'NgTableParams', '$task', '$http', 'ngToast',
function($state, $scope, $stateParams, $interval, $uibModal, $device,$deviceMessages, $device_model, $task, NgTableParams, $task, $http, ngToast) {

    $scope.editing = false;
    $scope.msg={};
    $scope.msg.live = false;

    $scope.toggleEdit = function() {
        $scope.editing = !$scope.editing;
    }
    var reloadDeviceMessages;

    $scope.liveReload = function(){
      if($scope.msg.live == false){
        reloadDeviceMessages = $interval(function() {
              $scope.deviceMessages.reload();
        }, 4000);
        $scope.msg.live = true;
        console.log("start liveReload");
      }else{
        $interval.cancel(reloadDeviceMessages);
        reloadDeviceMessages = undefined;
        $scope.msg.live = false;
        console.log("stopped live reload");
      }


    };

    // $scope.test = function(row){
    //     console.log(row);
    // };
    
    $scope.$on('$destroy', function() {
      // Make sure that the interval is destroyed too
      $scope.msg.live = false;
      $interval.cancel(reloadDeviceMessages);
      reloadDeviceMessages = undefined;
      console.log("stopped live reload");
    });

    // var socket = io(socketURI);
    // $scope.socket = socket;

    $scope.config_data = [];

    $scope.addRow = function(){
        $scope.config_data.push({
            port:$scope.config_data.length+1,description:'', time:300, isEditing: true
        });
    };

    $scope.save = function(row)
    {
        delete row.isEditing;
    }

    $scope.delRow = function(row)
    {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$device', function($state, $scope, $uibModalInstance, $device) {

                $scope.title = 'Deseja remover esse registro?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                var device = $scope.$parent.device;

                $scope.confirm = function() {
                    // TODO: fazer verificação se há controles usando essa porta.
                    var i = $scope.config_data.indexOf(row);
                    if(i > -1)
                        $scope.config_data.splice(i,1);

                    $uibModalInstance.dismiss('cancel');
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });


    }

    // $scope.tableParams = new NgTableParams({
    //         page: 1,
    //         count: 10,
    //         filter: { device_id: $stateParams.id }
    //     }, {
    //         counts: [],
    //         filterDelay: 0,
    //         getData: function(params) {
    //             return $task.query(params.url()).$promise.then(function(data) {
    //                 params.total(data.total);
    //                 return data.data;
    //             });
    //         }
    //     });

        $scope.tableParams = new NgTableParams({
                page: 1,
                count: 10,
            }, {
                filterDelay: 0,
                dataset: $scope.connectionLogs

            });

         $scope.deviceMessages = new NgTableParams({
                page: 1,
                count: 15,
                sorting: { created_at: 'desc'},
                filter: { id: $stateParams.id }
            }, {
                counts: [],
                filterDelay: 0,
                 getData: function(params) {
                    return $deviceMessages.query(params.url()).$promise.then(function(data) {
                      params.total(data.total);
                    //   data.data.forEach(item => {
                    //     item.message = JSON.stringify(item.message, null, "\t");
                    //   });
                      return data.data;
                    });
                }
            });
    $scope.msg={}

    $scope.sendMessage = function(message)
    { data={message:message}
      $scope.msg.messageToDevice="";
      $deviceMessages.sendMessage($stateParams.id,data).then(function(data){

      })
    }

    $scope.renewDevicePassword = function()
    { console.log("chamada renewDevicePassword");
      $deviceMessages.renewDevicePassword($stateParams.id).then(function(data){
        console.log(data);
        $scope.device.admin_password = data.new_password;
      })
    }


    $scope.latitude = -15.8404351;
    $scope.lagitude = -48.0305512;
    $scope.map = {
        center: {
            latitude: $scope.latitude,
            longitude: $scope.lagitude
        }, zoom: 16
    };


    $device.get({
            id: $stateParams.id
        }).$promise.then(function(data) {
            $scope.device = data.device;
            $scope.model  = data.model;
            $scope.connectionLogs = data.logs;
            console.log($scope.connectionLogs);
            $scope.config_data = (data.device.config != null) ? JSON.parse(data.device.config) : [];

            setTimeout(function () {
               
            }, 1500);
        }, function(error) {
            if (error.status == 404) {
                $state.go('notFound');
            }
        });


    $scope.confirmUpdate = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$device', function($scope, $uibModalInstance, $device) {

                $scope.title = 'Deseja atualizar?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Concluído';

                var device = {
                    latitude    : $scope.$parent.device.latitude,
                    longitude   : $scope.$parent.device.longitude
                };

                //device.config = $scope.$parent.config_data;

                $scope.confirm = function() {
                    $device.update({
                        id: $scope.$parent.device.hash
                    }, device).$promise.then(function(data) {
                        $uibModalInstance.dismiss('cancel');
                        $scope.$parent.toggleEdit();
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };
   

    $scope.signalTest = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'teste_signal.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$device', '_', function($state, $scope, $uibModalInstance, $device, _) {

                $scope.title = 'Teste de Sinal';
                $scope.ok_text = 'Ok';
                $scope.port = [];
                $scope.signal = { timer : false };

                $scope.device = $scope.$parent.device;
                $device.testSignal($scope.device.hash).then(function(response){
                    console.log(response);
                    setTimeout( function(){
                        $device.get({id: $scope.device.hash}).$promise.then(function(response){
                            console.log(response);
                            $scope.signal.wifi_latency          = response.device.wifi_latency;
                            $scope.signal.wifi_signal_strength  = response.device.wifi_signal_strength;
                            $scope.signal.wifi_quality          = ( parseFloat(response.device.wifi_signal_strength) < 0 ? ( parseFloat(response.device.wifi_signal_strength) + 100 ) * 2 : response.device.wifi_signal_strength);
                            $scope.signal.timer = true;
                        });
                    }, 1500);
                });

                _.each(JSON.parse($scope.device.config), function(config){
                    $scope.port[config.port] = {
                        description : config.description
                    };
                });

                $scope.test = function(port) {
                    $device.testSignal($scope.device.hash, {port: port}).then(function(response){
                        if( !$scope.port[port] )
                            $scope.port[port] = {};
                        $scope.port[port].tested = true;
                        $scope.port[port].test_success = true;
                    });
                };

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.confirmDelete = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$device', function($state, $scope, $uibModalInstance, $device) {

                $scope.title = 'Deseja remover esse registro?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                var device = $scope.$parent.device;

                $scope.confirm = function() {
                    $device.delete({id:device.hash}).$promise.then(function(data){
                        $uibModalInstance.dismiss('cancel');
                        $state.go('device');
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

    $scope.updateFirmware = function()
    {

        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$device', function($state, $scope, $uibModalInstance, $device) {

                $scope.title = 'Deseja enviar uma mensagem de solicitação de atualização de software para o Device?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                var device = $scope.$parent.device;

                $scope.confirm = function() {
                    $device.updateFirmware({id:$stateParams.id}).then(function(response){
                        $uibModalInstance.dismiss('cancel');
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });

    }

    /**
     * Test if the por is working
     */
    $scope.test = function(row)
    {   console.log(row);
        $device.testSignal($scope.device.hash, {port: row.port, time: row.time}).then(function(response){
            
        });
    }

    $scope.finish_test = function()
    {
        $http.post(api+'device/'+$stateParams.id+'/finish_test').then(function(response){
            ngToast.success({
                content: 'dispositivo instalado!',
                dismissOnTimeout: true,
                timeout: 3000
            });
        }, function(error){
            ngToast.danger({
                content: 'Erro!',
                dismissOnTimeout: true,
                timeout: 3000
            });
        });
    }

    $scope.reloadConfigs = function(){
        console.log("reloadConfigs");
        $device.getConfigs($stateParams.id).then(function(response) {
            $scope.configs = response;
        });
    }

    $scope.reloadConfigs();

    $scope.deleteConfig = function(id){

        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$device', function($state, $scope, $uibModalInstance, $device) {

                $scope.title = 'Deseja remover esse registro?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                $scope.confirm = function() {
                    $device.deleteConfig($stateParams.id, id).then(function(response){
                        $device.getConfigs($stateParams.id).then(function(response) {
                            $scope.configs = response;
                        });
                        $uibModalInstance.dismiss('cancel');
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.addConfig = function(){
        var modalInstance = $uibModal.open({
            animation      : true,
            ariaLabelledBy : 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl    : 'addDeviceConfig.modal.html',
            controller     : 'device.addConfig',
            size           : 'md',
            resolve        : {
                device: function(){
                    return angular.copy($scope.device)
                }
            }
        });

        modalInstance.result.then(function(response) {
            $device.getConfigs($stateParams.id).then(function(response) {
                $scope.configs = response;
            });
        });
    }

    $scope.editConfig = function(row){

        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'editDeviceConfig.modal.html',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$device','$client_access', function($state, $scope, $uibModalInstance, $device, $client_access) {

                $scope.title = 'Deseja remover esse registro?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                $client_access.query({client_id: $scope.$parent.device.client_id, count: 1000}).$promise.then(function(response){
                    $scope.accesses = response.data;
                });

                $scope.config = row;
                $scope.config.access_id = {id: row.access.id};

                $scope.confirm = function() {
                    var config = {
                        port        : $scope.config.port,
                        time        : $scope.config.time,
                        access_id   : $scope.config.access_id.id
                    };
                    $device.updateConfig(row.device_id, row.id, config).then(function(response){
                        $scope.reloadConfigs();
                        $uibModalInstance.close(response);
                    }, function(error){
                        console.log(error);
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

}]);

angular.module('device').
controller('device.addConfig', ['$state', '$scope', '$stateParams', '$uibModal', '$device', '$device_model', '$task', 'NgTableParams', '$task', 'device', '$client_access', '$uibModalInstance',
function($state, $scope, $stateParams, $uibModal, $device, $device_model, $task, NgTableParams, $task, device, $client_access, $uibModalInstance) {

    $scope.title        = 'Configuração';
    $scope.cancel_text  = 'Cancelar';
    $scope.success_text = 'Concluído';

    $scope.config = {
        port        : '',
        time        : '',
        device_input: false,
        access_id   : {}
    }

    $client_access.query({client_id: device.client_id, count: 1000}).$promise.then(function(response){
        $scope.accesses = response.data;
    });

    $scope.confirm = function()
    {   
        console.log($scope.config);
        var config = {
            device_id   : device.hash,
            port        : $scope.config.port,
            time        : $scope.config.time,
            input       : $scope.config.device_input,
            access_id   : $scope.config.access_id.id
        };
        $device.storeConfig(config.device_id, config).then(function(response){
            $uibModalInstance.close(response);
        }, function(error){
            console.log(error);
        });

    };

    $scope.cancel = function()
    {
        $uibModalInstance.dismiss('cancel');
    };

}]);
/**
 * Factory
 */
angular.module('device').
factory("$device", [ '$resource', '$http', '$q', function($resource,$http, $q) {
    var resource = $resource(api + 'device/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        }
    });

    // resource.getDeviceMessages= function(params){
    //   var deferred = $q.defer();
    //   $http.get(api+'device/'+params.id+'/deviceMessages', params).then(function(response){
    //       deferred.resolve(response.data);
    //   }, function(response) {
    //       deferred.reject(response);
    //   });
    //   return deferred.promise;
    // }



    resource.updateFirmware = function(params){
        var deferred = $q.defer();
        $http.get(api+'device/'+params.id+'/update_firmware', params).then(function(response){

            console.log(response);
            console.log(typeof response);

            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.getConfigs = function(id){
        var deferred = $q.defer();
        $http.get(api+'device/'+id+'/config').then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.deleteConfig = function(id, config_id){
        var deferred = $q.defer();
        $http.delete(api+'device/'+id+'/config/'+config_id).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.storeConfig = function(id, data){
        var deferred = $q.defer();
        $http.post(api+'device/'+id+'/config', data).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.updateConfig = function(device_id, id, data){
        var deferred = $q.defer();
        $http.put(api+'device/'+device_id+'/config/'+id, data).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.testSignal = function(id, data = {}){
        var deferred = $q.defer();
        $http.post(api+'device/'+id+'/signal_test', data).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.iAmOwner = function(id){
        var deferred = $q.defer();
        $http.get(api+'device/'+id+'/iamowner').then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.createAccess = function(id){
        var deferred = $q.defer();
        $http.get(api+'device/'+id+'/iamowner').then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.dashboard = function(){
        var deferred = $q.defer();
        $http.get(api+'device/dashboard/data').then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    return resource;
}]);

/**
 * Factory
 */
angular.module('device').
factory("$task", ['$resource', function($resource) {
    var resource = $resource(api + 'task/:id', null, {
        'query': {
            method: 'GET',
            isArray: false,
            transformResponse: function (response) {
                response = JSON.parse(response);
                _.each(response.data, function(task, key){
                    response.data[key].created_at = moment(task.created_at).format("DD/MM/YYYY HH:mm:ss");
                });
                return response;
            }
        },
        'update': {
            method: 'PUT'
        }
    });

    return resource;
}]);

angular.module('device').
factory("$deviceMessages", ['$resource','$q','$http', function($resource, $q, $http) {
    var resource = $resource(api+'device/deviceMessages', null, {
        'query': {
            method: 'GET',
            isArray: false,
            transformResponse: function (response) {
                response = JSON.parse(response);
                _.each(response.data, function(task, key){
                    response.data[key].created_at = moment(task.created_at).format("DD/MM/YYYY HH:mm:ss");
                });
                return response;
            }
        }
    });

    resource.sendMessage = function(id, data){
        var deferred = $q.defer();
        $http.post(api+'device/'+id+'/messageToDevice', data).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.renewDevicePassword = function(id){
        var deferred = $q.defer();
        $http.get(api+'device/'+id+'/newPassword').then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }


    return resource;
}]);

angular.module("confirm.modal.html", []).run(["$templateCache", function($templateCache) {
    $templateCache.put('confirm.modal.html',
        '<div class="modal-body">' +
        ' {{title}}' +
        '</div>' +
        '<div class="modal-footer">' +
        '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '    <button class="btn btn-outline btn-success" type="button" ng-click="confirm()">{{success_text}}</button>' +
        '</div>'
    );
}]);
