angular.module('doorman', ['ui.router','confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap']);

angular.module('doorman').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

    $stateProvider
    .state('doorman', {
        url         : "/doorman",
        parent: 'app.admin',
        views: {
            'content':{
                templateUrl : server+"/views/m/doorman/panel",
                controller  : 'doorman.panel',
            }
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Portaria'
        }
    })

    .state('doorman_list', {
        url         : "/doorman/history",
        parent: 'app.admin',
        views: {
            'content':{
                templateUrl : server+"/views/m/doorman/index",
                controller  : 'doorman.list',
            }
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Portaria'
        }
    })

    .state('doorman_create', {
        url         : "/doorman/create",
        parent: 'app.admin',
        views: {
            'content':{
                templateUrl : server+"/views/m/doorman/create",
                controller  : 'doorman.create',
            }
        },
        ncyBreadcrumb: {
            parent: 'doorman',
            label: 'Adicionar'
        }
    })

    .state('doorman_show', {
        url         : "/doorman/:id",
        parent: 'app.admin',
        views: {
            'content':{
                templateUrl : server+"/views/m/doorman/show",
                controller  : 'doorman.show',
            }
        },
        ncyBreadcrumb: {
            parent: 'doorman',
            label: '{{doorman.id}}'
        }
    })

    .state('doorman_config_show', {
        url         : "/doormanConfig/:id",
        parent: 'app.admin',
        views: {
            'content':{
                templateUrl : server+"/views/m/doormanConfig/show",
                controller  : 'doorman.showConfig',
            }
        },
        ncyBreadcrumb: {
            parent: 'doormanConfigShow',
            label: '{{doormanConfig.id}}'
        }
    })

    .state('doorman_config_list', {
        url         : "/doormanConfig",
        parent: 'app.admin',
        views: {
            'content':{
                templateUrl : server+"/views/m/doormanConfig",
                controller  : 'doorman.listConfig',
            }
        },
        ncyBreadcrumb: {
            parent: 'doormanConfigList',
            label: '{{doormanConfigList}}'
        }
    })

}]);


/**
 * show Controller
 */
 angular.module('doorman').
 controller('doorman.showConfig', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$doorman','$stateParams', function($scope, $http, $auth, $q, NgTableParams, $doorman,$stateParams) {
    $doorman.getConfig($stateParams.id).then(function(data) {
        $scope.config = data;
    })
 }]);

 angular.module('doorman').
 controller('doorman.listConfig', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$doorman','$stateParams', function($scope, $http, $auth, $q, NgTableParams, $doorman,$stateParams) {
    $scope.configTableParams = new NgTableParams({
        page: 1,
        count: 10
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $doorman.getConfigList(params.url()).then(function(data) {
                params.total(data.total);
                console.log(data.data);
                return data.data;
            });
        }
    });
 }]);


/**
 * List Controller
 */
angular.module('doorman').
controller('doorman.list', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$doorman', function($scope, $http, $auth, $q, NgTableParams, $doorman) {
   
    
   $scope.tableParams = new NgTableParams({
        page: 1,
        count: 10
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $doorman.query(params.url()).$promise.then(function(data) {
                params.total(data.total);
                return data.data;
             });
        }
    });

   
}]);

/**
 * List Controller
 */
 angular.module('doorman').
 controller('doorman.panel', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$doorman', function($scope, $http, $auth, $q, NgTableParams, $doorman) {
     var socket = io('https://portaria.keyset.app',{ transports : ['websocket'] });
     
    $scope.atendimentos = [];
    $scope.refresh =function(){
     $scope.atendimentos =[];
     $doorman.query({
                 page: 1,
                 count: 10
             }).$promise.then(function(data) {
                 console.log(data.data);
                    
                    data.data.forEach(element => {
                        element.mainImage = element.images[0];
                        element.visitorAge = Math.round(element.visitorAge);
                        if(element.visitorGender=="male")
                        element.visitorGender="M";
                        else
                        element.visitorGender="F";
                        element.errors = JSON.parse(element.errors);

                        var now = moment();
                        element.created_at = moment(element.created_at, "YYYY-MM-DD hh:mm:ss");
                        if(moment.duration(now.diff(element.created_at)).as('minutes')<30){
                            element.created_at = moment(element.created_at, "YYYY-MM-DD hh:mm:ss").fromNow();
                            $scope.atendimentos.push(element);
                        }
                        
                    });
   
                      
                    return data.data;
         });
 
    }

    $scope.refresh();
     socket.on('new_doorman_call',()=>{
         console.log("new call ");
         $scope.refresh();
     })
 
     socket.on('new_doorman_info',params=>{
            if(params=="error"){
                var audio = new Audio('img/alert.wav');
                audio.play();
            }
            

         console.log("new info"+params);
         $scope.refresh();
     })
 
     socket.on('doorman_context_complete',params=>{
         console.log("context_complete"+params);
     })
 }]);

/**
 * Create Controller
 */
angular.module('doorman').
controller('doorman.create', ['$scope', '$state', '$doorman', '$client','$client_access',
function($scope, $state, $doorman, $client, $client_access) {
   $scope.config={};
   params = {
       count:1000
   }
   $client.query(params).$promise.then(function(data) {
       $scope.clients=data.data;
   });
   

   $scope.getAccesses = function(){
        $client_access.query({client_id:$scope.config.client.id,count:100000}).$promise.then(function(data) {
        $scope.accesses = data.data;
    });
    }

    $scope.save = function(){
        
        $doorman.newDoorman($scope.config).then(function(data) {
            $state.go('doorman_config_show', {
                id: data.id
            });
        });
    }
    
   
}]);

/**
 * Show, Edit, Delete Controller
 */

angular.module('doorman').
controller('doorman.show', ['$state', '$scope', '$stateParams',  '$doorman',
function($state, $scope, $stateParams, $doorman) {

    var socket = io('https://portaria.keyset.app',{ transports : ['websocket'] });

    $scope.openDoor = function(){

        
    }
   
    $doorman.get({
        id: $stateParams.id
    }).$promise.then(function(data) {
       $scope.call = data;
       console.log(data);
       if($scope.call.visitorGender == "male"){
        $scope.call.visitorGender = "masculino"
       }else{
        $scope.call.visitorGender = "feminino"
       }
       switch ($scope.call.call_intent) {
        case "visit":
            $scope.call.call_intent = "Visitante"
            break;
        case "delivery":
            $scope.call.call_intent = "Entrega"
            break;
        case "foodDelivery":
            $scope.call.call_intent = "Entrega de Comida"
            break;
       
           default:
               break;
       }
       $scope.call.visitorAge = Math.round($scope.call.visitorAge);
       $scope.call.errors = JSON.parse($scope.call.errors);
       $scope.call.errorsParsed = [];
       if($scope.call.errors){
        $scope.call.errors.forEach(element => {
            switch (element) {
                case "user_not_found":
                     $scope.call.errorsParsed.push("Usuário não encontrado");
                    break;
                 case "unit_not_found":
                     $scope.call.errorsParsed.push("Número de apartamento ou casa não encontrado");
                    break;
                default:
                     $scope.call.errorsParsed.push(element);
                    break;
            }
        });
       }
       
       $scope.call.created_at = moment($scope.call.created_at).format('DD/MM/YYYY h:mm:ss a')
    }, function(error) {
        if (error.status == 404) {
            $state.go('notFound');
    }

    });

}]);



angular.module('doorman').
factory("$doorman", [ '$resource', '$http','$q',function($resource, $http, $q) {
    var resource = $resource(api + 'doorman/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        },
    });

    resource.newDoorman = function(newDoorman){
        var deferred = $q.defer();
        $http.post(api+'doorman/new',newDoorman).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }


    resource.getConfig = function(id){
        var deferred = $q.defer();
        $http.get(api+'doorman/config/'+id).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.getConfigList = function(){
        var deferred = $q.defer();
        $http.get(api+'doorman/doormanconfig/list').then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }
    
    return resource;
}]);
