angular.module('profile', ['ui.router', 'ngFileUpload', 'ngImgCrop']);

angular.module('profile').
config(['$stateProvider','$urlRouterProvider', function($stateProvider,$urlRouterProvider) {
    $stateProvider

    .state('profile', {
        url         : "/profile",
        parent: 'app.user',
        views: {
            'content':{
                templateUrl : server+"/views/m/profile",
                controller  : 'profile',
            }
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Perfil'
        }
    })
}]);


angular.module('profile').
controller('profile', ['$rootScope','$scope', '$http', '$auth','$state', '$localStorage', '$user', '$uibModal','Upload', '$templateCache', 'ngToast', function($rootScope, $scope, $http, $auth, $state, $localStorage, $user, $uibModal, Upload, $templateCache, ngToast) {

    $scope.editing = false;
    $scope.toggleEdit = function(){
        $scope.editing = !$scope.editing;
    }

    $scope.isChangingPassword = false;

    $scope.changePassword = {
    };

    $scope.user = $localStorage.user;

    $scope.confirmUpdate = function()
    {
        $http.put(api+'user/profile', $scope.user).then(function(response){
            $scope.toggleEdit();
            $localStorage.user = $scope.user;
            location.reload();
        });
    };

    $scope.updatePassword = function()
    {
        $http.put(api+'user/update_password', $scope.changePassword).then(function(response){
            $scope.isChangingPassword = false;
            ngToast.success({
                content: 'Senha alterada com sucesso.',
                dismissOnTimeout: true,
                timeout: 3000
            });
            $scope.changePassword = {};
        }, function(error){
            ngToast.danger({
                content: 'Senha não confere',
                dismissOnTimeout: true,
                timeout: 3000
            });
            $scope.changePassword.current = '';
        });


    }

    $scope.updatePicture = function()
    {
        $templateCache.put('endOffer.modal.html',
            '<div class="modal-body text-center">'+

                '<button ng-show="!$ctrl.image" class="btn btn-lg btn-primary btn-block" ngf-select ng-model="$ctrl.image" accept="image/*" ><i class="fa fa-plus"></i> Carregar foto</button>'+

                '<div ng-if="$ctrl.image && $ctrl.progress == 0" ngf-drop ng-model="$ctrl.image" ngf-pattern="image/*" class="cropArea">'+
                    '<img-crop image="$ctrl.image | ngfDataUrl" area-min-size="200" result-image="$ctrl.croppedDataUrl" ng-init="$ctrl.croppedDataUrl=\'\'"></img-crop>'+
                '</div>'+


                '<div ng-if="$ctrl.progress > 0" class="progress">'+
                    '<div class="progress-bar progress-bar-striped active" role="progressbar" aria-valuenow="45" aria-valuemin="0" aria-valuemax="100" style="width: {{$ctrl.progress}}%">'+
                        '<span class="sr-only">{{$ctrl.progress}}%</span>'+
                    '</div>'+
                '</div>'+

            '</div>'+
            '<div ng-if="$ctrl.image" class="modal-footer">'+
                '<button class="btn btn-lg btn-link" type="button" ng-click="$ctrl.cancel()">Cancelar</button>'+
                '<button class="btn btn-lg btn-success" type="button" ng-disabled="$ctrl.progress > 0" ng-click="$ctrl.upload($ctrl.croppedDataUrl, $ctrl.image.name)">Salvar</button>'+
            '</div>'
        );

        var modalInstance = $uibModal.open({
            animation         : true,
            ariaLabelledBy    : 'modal-title',
            ariaDescribedBy   : 'modal-body',
            windowTemplateUrl : 'windowCentered.modal.html',
            templateUrl       : 'endOffer.modal.html',
            controller        : 'updateAvatar',
            controllerAs      : '$ctrl'
        });

        modalInstance.result.then(function (avatar) {
            $scope.user.avatar = avatar+'?' + new Date().getTime();
            $localStorage.user = $scope.user;
        });
    }

}]);

angular.module('profile').
controller('updateAvatar', ['$uibModalInstance','Upload', '$timeout', function($uibModalInstance, Upload, $timeout){
    var $ctrl = this;

    $ctrl.image;

    $ctrl.progress = 0;

    $ctrl.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };

    $ctrl.upload = function (dataUrl, name) {
        Upload.upload({
            url: api+'user/avatar',
            data: {
                image: Upload.dataUrltoBlob(dataUrl, name)
            },
        }).then(function (response) {
            $timeout(function () {
                $uibModalInstance.close(response.data.image);
            }, 500);
        }, function (response) {
            if (response.status > 0) console.log(response.status + ': ' + response.data);
        }, function (evt) {
            $ctrl.progress = parseInt(100.0 * evt.loaded / evt.total);
        });
    }
}]);

/**
 * Factory
 */
angular.module('profile').
factory("$user", [ '$resource', '$q', '$http', function($resource, $q, $http) {
    var resource = $resource(api + 'user/:id', null, {
        'query': {
            method: 'GET',
            isArray: false,
            transformResponse: function (response) {
                response = JSON.parse(response);
                _.each(response.data, function(data, key){

                });
                return response;
            }
        },
        'update': {
            method: 'PUT'
        }
    });

    resource.updateProfile = function(data){
        var deferred = $q.defer();
        $http.put(api+'user/profile', data).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    return resource;
}]);
