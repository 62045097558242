angular.module('templates', []);

angular.module('templates').run([ '$templateCache' ,function($templateCache) {

    $templateCache.put('confirm.modal.html',
        '<div class="modal-header text-center" ng-if="header">' +
            '<h4 class="title" style="margin:0px">{{header}}</h4>'+
        '</div>' +
        '<div class="modal-body text-center">' +
        ' {{title}}' +
        '</div>' +
        '<div class="modal-footer">' +
        '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '    <button class="btn btn-success" type="button" ng-click="confirm()">'+
        '        <span>{{success_text}}</span>'+
        '    </button>' +
        '</div>'
    );

    $templateCache.put('addblocks.modal.html',
        '<div class="modal-body text-center">' +

            '<div class="row">' +
                '<div class="col-md-6 form-group">' +
                    '<label class="control-label text-left">Quantidade de Andares:</label>' +
                    '<input type="text" class="form-control" name="version" ng-model="floors" />' +
                '</div>' +

                '<div class="col-md-6 form-group">' +
                    '<label class="control-label text-left">Apartamentos por andar:</label>' +
                    '<input type="text" class="form-control" name="version" ng-model="apartments" />' +
                '</div>' +
            '</div>' +

        '</div>' +
        '<div class="modal-footer">' +
        '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '    <button class="btn btn-success" type="button" ng-click="confirm()">{{success_text}}</button>' +
        '</div>'
    );

    $templateCache.put('adddevice.modal.html',
        '<div class="modal-body text-center">' +
            '<div class="row">' +
                '<div class="col-md-12 form-group">'+
                    '{{selected}}'+
                    '<table ng-table="devices" show-filter="true" class="table table-condensed table-striped table-pointer table-hover">' +
                        '<tr ng-repeat="row in $data track by row.hash" ng-click="select(row)" ng-class="{active: _.contains(selected_devices, row.hash)}">' +
                            '<td sortable="\'online\'" class="col-md-1 text-center">' +
                                '<i class="fa fa-power-off" ng-class="{\'text-danger\': !row.online, \'text-success\': row.online}"></i>' +
                            '</td>' +
                            '<td class="col-md-2" title="\'#\'" filter="{hash: \'text\'}" sortable="\'hash\'">#{{row.hash}}</td>' +
                            '<td class="col-md-3" title="\'Número de serie\'" filter="{serial: \'text\'}" sortable="serial">{{row.serial}}</td>' +
                            '<td title="\'Modelo\'" filter="{\'device_model.name\': \'text\'}" sortable="\'device_model.name\'">{{row.model.name}}</td>' +
                        '</tr>' +
                    '</table>' +
                '</div>' +
            '</div>' +
        '</div>' +
        '<div class="modal-footer">' +
        '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '    <button class="btn btn-success" type="button" ng-disabled="selected_devices.length <= 0" ng-click="confirm()">{{success_text}}</button>' +
        '</div>'
    );

    $templateCache.put('sendBinary.modal.html',
        '<div class="modal-body text-center">' +

            '<div class="row">' +
                '<div class="col-md-12 form-group"' +
                    '<label class="control-label">Versão:</label>' +
                    '<input type="text" class="form-control" name="version" ng-model="$ctrl.version" />' +
                '</div>' +

                '<div ng-if="$ctrl.progress > 0" class="progress">' +
                    '<div class="progress-bar progress-bar-striped active" role="progressbar" aria-valuenow="45" aria-valuemin="0" aria-valuemax="100" style="width: {{$ctrl.progress}}%">' +
                        '<span class="sr-only">{{$ctrl.progress}}%</span>' +
                    '</div>' +
                '</div>' +

            '</div>' +

            '<button ng-show="!$ctrl.file" class="btn btn-lg btn-primary btn-block" ngf-select ng-model="$ctrl.file" accept="*" ><i class="fa fa-plus"></i> Carregar arquivo</button>' +


        '</div>' +
        '<div ng-if="$ctrl.file" class="modal-footer">' +
            '<button class="btn btn-lg btn-link" type="button" ng-click="$ctrl.cancel()">Cancelar</button>' +
            '<button class="btn btn-lg btn-success" type="button" ng-disabled="$ctrl.progress > 0 || !$ctrl.version" ng-click="$ctrl.upload($ctrl.file,$ctrl.version)">Enviar</button>' +
        '</div>'
    );

    $templateCache.put('erro.modal.html',
        '<div class="modal-header text-center">' +
            '<h1 class="title">{{title}}</h1>'+
        '</div>' +
        '<div class="modal-body text-center">' +
        ' {{message}}' +
        '</div>' +
        '<div class="modal-footer">' +
        '    <button class="btn btn-danger btn-info" type="button" ng-click="cancel()">OK</button>' +
        '</div>'
    );

    $templateCache.put('info.modal.html',
        '<div class="modal-header text-center">' +
            '<h1 class="title">{{title}}</h1>'+
        '</div>' +
        '<div class="modal-body">' +
            '<table class="table table-condensed table-striped">' +
                '<tbody>' +
                    '<tr>' +
                        '<td class="col-md-2"><strong>Arquivo:</strong></td>' +
                        '<td class="col-md-10">{{info.name}}</td>' +
                    '</tr>' +

                    '<tr>' +
                        '<td class="col-md-2"><strong>Extensão:</strong></td>' +
                        '<td class="col-md-10">{{info.extension}}</td>' +
                    '</tr>' +

                    '<tr>' +
                        '<td class="col-md-2"><strong>Criado em:</strong></td>' +
                        "<td class=\"col-md-10\">{{info.created_at | date:'dd/MM/yyyy'}}</td>" +
                    '</tr>' +

                    '<tr>' +
                        '<td class="col-md-2"><strong>Criado por:</strong></td>' +
                        '<td class="col-md-10">{{info.user.name}}</td>' +
                    '</tr>' +
                '</tbody>' +
            '</table>' +
        '</div>' +
        '<div class="modal-footer">' +
        '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{success_text}}</button>' +
        '</div>'
    );

    $templateCache.put('forgot.modal.html',
        '<div class="modal-header text-center">' +
            '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
            '<h1 class="title">{{title}}</h1>'+
        '</div>' +
        '<div class="modal-body">' +
            '<div class="row" ng-if="!requested">' +
                '<form name="form_step" ng-submit="request()">'+
                    '<div class="col-md-10">'+
                        '<label>Digite seu email cadastrado: </label>' +
                        '<input type="text" class="form-control" ng-model="user.email" required />' +
                    '</div>'+
                    '<div class="col-md-2">'+
                        '<label>&nbsp;</label><br>' +
                        '<button class="btn btn-success" type="button" ng-disabled="form_step.$invalid" ng-click="request()">{{success_text}}</button>' +
                    '</div>'+
                '</form>'+
            '</div>' +
            '<div class="row" ng-if="requested">' +
                '<form name="form_step" ng-submit="reset()">'+
                    '<div class="col-md-12">'+
                        '<div class="alert alert-info text-center" role="alert">'+
                            'Enviamos um email com o codigo de confirmação, verifique sua caixa de entrada!'+
                        '</div>'+
                    '</div>'+
                    '<div class="col-md-12">'+
                        '<label>Código: </label>' +
                        '<input type="text" class="form-control" ng-model="user.code" required />' +
                    '</div>'+
                    '<div class="col-md-6">'+
                        '<label>Senha: </label>' +
                        '<input type="password" class="form-control" ng-model="user.password" required />' +
                    '</div>'+
                    '<div class="col-md-6">'+
                        '<label>Repetir senha: </label>' +
                        '<input type="password" class="form-control" ng-model="user.confirm" required />' +
                    '</div>'+
                    '<div class="col-md-12">'+
                        '<label>&nbsp;</label><br>' +
                        '<button class="btn btn-success" type="button" ng-disabled="form_step.$invalid || user.password != user.confirm" ng-click="reset()">{{success_text}}</button>' +
                    '</div>'+
                '</form>'+
            '</div>' +
        '</div>'
    );

    $templateCache.put('sendfile.modal.html',
        '<div class="modal-header">'+
            '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
            '<h4 class="modal-title text-center">Enviar arquivos</h4>'+
        '</div>'+
        '<div class="modal-body upload-form">' +
            '<div class="drop-box" required name="upload_files" ng-model="upload_files" ngf-select ngf-drop accept="*" ngf-max-size="10MB" ngf-drop-available="true" ngf-multiple="multiple" multiple="multiple" ngf-keep="true" ngf-model-invalid="errorFile" ngf-max-files="5" ngf-drag-over-class="dragOverClassObj">'+
                '<i class="fa fa-cloud-upload" aria-hidden="true"></i> Selecione os arquivos ou arraste.'+
            '</div>'+

            '<ul class="response">'+
                '<li class="sel-file" ng-repeat="f in invalidFiles">'+
                    '<div>Invalid File: {{f.$errorMessages}} {{f.$errorParam}}, {{f.name}} - size: {{f.size}}B - type: {{f.type}}</div>'+
                '</li>'+
            '</ul>'+
        '</div>'+

        '<table class="table table-striped table-condensed upload-form">'+
            '<tbody>'+
                '<tr ng-repeat="f in upload_files">'+
                    '<td class="col-md-8">{{f.name}}</td>'+
                    '<td class="col-md-4"><uib-progressbar ng-show="f.progress >= 0" value="f.progress">{{f.progress}}%</uib-progressbar></td>'+
                '</tr>'+
            '</tbody>'+
        '</table>'+

        '<div class="modal-footer">' +
        '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '    <button class="btn btn-success" type="button" ng-click="confirm()">{{success_text}}</button>' +
        '</div>'
    );

    $templateCache.put('discount.modal.html',
        '<div class="modal-header">'+
            '<button type="button" class="close" ng-click="$ctrl.cancel()"><span aria-hidden="true">&times;</span></button>'+
            '<h4 class="modal-title">Disconto</h4>'+
        '</div>'+

        '<div class="modal-body">'+
            '<form name="form_step">'+
                '<div class="row">'+
                    '<div class="col-md-3 form-group">'+
                        '<label>Valor <span class="text-danger">*</span></label>'+
                        '<p class="text-right">{{$ctrl.data.value | finance:true}}</p>'+
                    '</div>'+

                    '<div class="col-md-3 form-group">'+
                        '<label>Desconto <span class="text-danger">*</span></label>'+
                        '<input type="text" class="form-control text-right" ng-model="$ctrl.data.discount" name="discount" ui-money-mask required/>'+
                    '</div>'+

                    '<div class="col-md-3 form-group">'+
                        '<label>Total <span class="text-danger">*</span></label>'+
                        '<p class="text-right">{{$ctrl.data.value - $ctrl.data.discount | finance:true}}</p>'+
                    '</div>'+
                '</div>'+

                '<div class="row">'+
                    '<div class="col-md-12 form-group">'+
                        '<label>Motivo</label>'+
                        '<textarea name="reason" ng-model="$ctrl.data.reason" cols="30" rows="2" class="form-control" required></textarea>'+
                    '</div>'+
                '</div>'+

            '</form>'+
        '</div>' +

        '<div class="modal-footer">' +
        '    <button class="btn btn-link btn-info" type="button" ng-click="$ctrl.cancel()">{{cancel_text}}</button>' +
        '    <button class="btn btn-success" type="button" ng-click="$ctrl.confirm()" ng-disabled="form_step.$invalid || $ctrl.data.discount <= 0 || $ctrl.data.discount > $ctrl.data.value">{{success_text}}</button>' +
        '</div>'
    );

    $templateCache.put('os.modal.html',
        '<div class="modal-body">' +

            '<div class="row">'+
                '<div class="col-md-12 form-group">'+
                    '<label>Cliente</label>'+
                    '<div ng-dropdown-multiselect options="clients" selected-model="client" extra-settings="selectConfig" translation-texts="translation"></div>'+
                '</div>'+
            '</div>'+

        '</div>' +
        '<div class="modal-footer">' +
        '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '    <button class="btn btn-success" type="button" ng-disabled="!client.id" ng-click="confirm()">{{success_text}}</button>' +
        '</div>'
    );

    $templateCache.put("uib/template/rating/rating.html",
        "<span ng-mouseleave=\"reset()\" ng-keydown=\"onKeydown($event)\" tabindex=\"0\" role=\"slider\" aria-valuemin=\"0\" aria-valuemax=\"{{range.length}}\" aria-valuenow=\"{{value}}\" aria-valuetext=\"{{title}}\">\n" +
            "<span ng-repeat-start=\"r in range track by $index\" class=\"sr-only\">({{ $index < value ? '*' : ' ' }})</span>\n" +

            "<span ng-repeat-end class=\"fa-stack fa-3x\" ng-mouseenter=\"enter($index + 1)\" ng-click=\"rate($index + 1)\">"+
                "<i class=\"fa fa-stack-2x\" ng-class=\"$index < value && (r.stateOn || 'glyphicon-star') || (r.stateOff || 'glyphicon-star-empty')\" ng-attr-title=\"{{r.title}}\"></i>\n"+
                "<strong class=\"fa fa-stack-1x\" ng-class=\"$index < value && ('fa-inverse') || ('')\">{{$index+1}}</strong>"+
            "</span>"+

        "</span>\n" +
        ""
    );

    $templateCache.put('windowCentered.modal.html',
        "<div modal-render=\"{{$isRendered}}\" tabindex=\"-1\" role=\"dialog\" class=\"modal\"\n" +
        "    uib-modal-animation-class=\"fade\"\n" +
        "    modal-in-class=\"in\"\n" +
        "    ng-style=\"{'z-index': 1050 + index*10, display: 'block'}\">\n" +
            '<div class="vertical-alignment-helper">'+
                '<div class="vertical-align-center">'+
                    "<div class=\"modal-dialog {{size ? 'modal-' + size : ''}}\"><div class=\"modal-content avatar-modal\" uib-modal-transclude></div></div>\n" +
                '</div>'+
            '</div>'+
        "</div>\n"
    );

    $templateCache.put('accounts_table.html',
        "<div class=\"table-responsive\">\n" +
        " <table class=\"table table-condensed table-hover table-pointer tree-grid\">\n" +
        "   <thead>\n" +
        "     <tr>\n" +
        "       <th><a ng-if=\"expandingProperty.sortable\" ng-click=\"sortBy(expandingProperty)\">{{expandingProperty.displayName || expandingProperty.field || expandingProperty}}</a><span ng-if=\"!expandingProperty.sortable\">{{expandingProperty.displayName || expandingProperty.field || expandingProperty}}</span><i ng-if=\"expandingProperty.sorted\" class=\"{{expandingProperty.sortingIcon}} pull-right\"></i></th>\n" +
        "       <th class=\"{{col.headerClass}}\" ng-repeat=\"col in colDefinitions\"><a ng-if=\"col.sortable\" ng-click=\"sortBy(col)\">{{col.displayName || col.field}}</a><span ng-if=\"!col.sortable\">{{col.displayName || col.field}}</span><i ng-if=\"col.sorted\" class=\"{{col.sortingIcon}} pull-right\"></i></th>\n" +
        "     </tr>\n" +
        "   </thead>\n" +
        "   <tbody>\n" +
        "     <tr ng-repeat=\"row in tree_rows | searchFor:$parent.filterString:expandingProperty:colDefinitions track by row.branch.uid\"\n" +
        "       ng-class=\"'level-' + {{ row.level }} + (row.branch.selected ? ' active':'')\" class=\"tree-grid-row\">\n" +
        "       <td><a ng-click=\"user_clicks_branch(row.branch)\"><i ng-class=\"row.tree_icon\"\n" +
        "              ng-click=\"row.branch.expanded = !row.branch.expanded\"\n" +
        "              class=\"indented tree-icon\"></i></a><span ng-if=\"expandingProperty.cellTemplate\" class=\"indented tree-label\" " +
        "              ng-click=\"on_user_click(row.branch)\" compile=\"expandingProperty.cellTemplate\"></span>" +
        "              <span  ng-if=\"!expandingProperty.cellTemplate\" class=\"indented tree-label\" ng-click=\"on_user_click(row.branch)\">\n" +
        "             {{row.branch[expandingProperty.field] || row.branch[expandingProperty]}}</span>\n" +
        "       </td>\n" +
        "       <td class=\"{{col.colClass}}\" ng-repeat=\"col in colDefinitions\">\n" +
        "         <div ng-if=\"col.cellTemplate\" compile=\"col.cellTemplate\" cell-template-scope=\"col.cellTemplateScope\"></div>\n" +
        "         <div ng-if=\"!col.cellTemplate\">{{row.branch[col.field]}}</div>\n" +
        "       </td>\n" +
        "     </tr>\n" +
        "   </tbody>\n" +
        " </table>\n" +
        "</div>\n" +
        ""
    );

    $templateCache.put('newInvite.modal.html',
        '<form name="form" ng-submit="confirm()">'+
            '<div class="modal-header">'+
                '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
                '<h4 class="modal-title">{{title}}</h4>'+
            '</div>'+

            '<div class="modal-body">'+
                '<div class="row">'+
                    '<div class="col-md-4">'+
                        '<label>Controles</label>'+
                        '<div class="form-check form-check-inline">'+
                            '<label class="checkbox-inline" ng-repeat="row in controls track by $index">'+
                                '<input type="checkbox" ng-model="controls[$index].allow" ng-checked="controls[$index].allow"> '+
                                '{{row.description}}'+
                            '</label>'+
                        '</div>'+
                    '</div>'+
                    '<div class="col-md-4">'+
                        '<label>Bloco</label>'+
                        '<select class="form-control" ng-options="item as item.name for item in apartment_buildings" ng-model="invite.block" required="required">'+
                            '<option value="">Selecione</option>'+
                        '</select>'+
                    '</div>'+
                    '<div class="col-md-4">'+
                        '<label>Unidade</label>'+
                        '<select class="form-control" name="unit" ng-options="item.id as item.number for item in units" ng-model="invite.client_unit_id" required="required" ng-valid="invite.unid">'+
                            '<option value="">Selecione</option>'+
                        '</select>'+
                    '</div>'+
                '</div>'+
                '<div class="row">'+
                    '<div class="col-md-4">'+
                        '<label>Nome *</label>'+
                        '<input type="text" placeholder="Nome do Convidado" class="form-control" ng-model="invite.name" style="background-color: #fff; border: #cecece 1px solid; color:#000" required/>'+
                    '</div>'+
                    // '<div class="col-md-1">'+
                    //     '<label>País *</label>'+
                    //     '<input type="text" ng-value=" +55" class="form-control" ng-model="invite.areaCode" style="background-color: #fff; border: #cecece 1px solid; color:#000"/>'+
                    // '</div>'+
                    '<div class="col-md-3">'+
                        '<label>Telefone com DDD *</label>'+
                        '<input type="text" placeholder="(DDD) XXXX-XXXX" class="form-control" ng-model="invite.phone" style="background-color: #fff; border: #cecece 1px solid; color:#000" required ui-br-phone-number />'+
                    '</div>'+
                    '<div class="col-md-4">'+
                        '<label>Email (opcional)</label>'+
                        '<input type="email" placeholder="email@mail.com" class="form-control" ng-model="invite.email" style="background-color: #fff; border: #cecece 1px solid; color:#000" />'+
                    '</div>'+
                    
                '</div>'+
                '<div class="row" >'+
                    '<div class="col-md-4">'+
                        '<div class="form-check form-check-inline">'+
                            '<label class="checkbox-inline">'+
                                '<input type="checkbox" ng-model="invite.limitedAccess" ng-checked="limitedAccess"> '+
                                'Limitar Acesso'+
                            '</label>'+
                        '</div>'+
                    '</div>'+
                '</div>'+
                
                '<div class="row" ng-show=invite.limitedAccess>'+
                    '<div class="col-md-12" style="margin-top:2em, margin-bottom:2em">'+
                        '<div class="btn-group" role="group">'+
                            '<button ng-repeat="day in daysOfWeek track by $index" ng-click="selectDay($index)" type="button" class="btn" ng-class= "{\'btn-primary\': day.selected}"> {{day.label}}</button>'+
                        '</div>'+
                    
                        '<div class="row">'+
                            '<div class="col-md-2">'+
                                '<uib-timepicker ng-model="start_at"  hour-step="hstep" minute-step="mstep" show-spinners="false" show-meridian="false"></uib-timepicker>'+
                               
                            '</div>'+
                            '<div class="col-md-1">'+
                              '<label>até</label>'+
                            '</div>'+
                            '<div class="col-md-2">'+
                                '<uib-timepicker ng-model="end_at" hour-step="hstep" minute-step="mstep" show-spinners="false" show-meridian="false"></uib-timepicker>'+
                               
                            '</div>'+
                        '</div>'+
                        '<div class="row">'+
                            '<div class="col-md-2">'+
                                '<uib-timepicker ng-model="start_at2"  hour-step="hstep" minute-step="mstep" show-spinners="false" show-meridian="false"></uib-timepicker>'+
                               
                            '</div>'+
                            '<div class="col-md-1">'+
                                '<label>até</label>'+
                            '</div>'+
                            '<div class="col-md-2">'+
                                '<uib-timepicker ng-model="end_at2"  hour-step="hstep" minute-step="mstep" show-spinners="false" show-meridian="false"></uib-timepicker>'+
                               
                            '</div>'+
                        '</div>'+
                        '<div class="row">'+
                            '<div class="col-md-2">'+
                                '<uib-timepicker ng-model="start_at3"  hour-step="hstep" minute-step="mstep"  show-spinners="false" show-meridian="false"></uib-timepicker>'+
                                
                            '</div>'+
                            '<div class="col-md-1">'+
                                '<label>até</label>'+
                            '</div>'+
                            '<div class="col-md-2">'+
                                '<uib-timepicker ng-model="end_at3" hour-step="hstep" minute-step="mstep" show-spinners="false" show-meridian="false"></uib-timepicker>'+
                                
                            '</div>'+
                            '<div class="col-md-2">'+
                            '<button  ng-click="cocStandardSchedule()" type="button" class="btn"> Horario Padrao COC</button>'+
                            '</div>'+
                            
                        '</div>'+
                    '</div>'+
                '</div>' +
            '</div>' +

            '<div class="modal-footer">' +
            '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
            '    <button class="btn btn-success" type="submit" ng-disabled="form.$invalid">{{success_text}}</button>' +
            '</div>'+
        '</form>'
    );

    $templateCache.put('importCSV.modal.html',
        '<form name="form_step">'+
            '<div class="modal-content">'+
                '<div class="modal-header">'+
                    '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
                    '<h4 class="modal-title">Convites</h4>'+
                '</div>'+

                '<div class="modal-body">'+

                    '<div ng-if="saving">'+
                        '<div class="progress form-group">'+
                            '<div class="progress-bar progress-bar-striped active" role="progressbar" aria-valuenow="{{progress}}" aria-valuemin="0" aria-valuemax="100" style="width:{{progress}}%">'+
                                '{{sent}}/{{invite.data.length}}'+
                            '</div>'+
                        '</div>'+
                        '<span class="text-{{row.type}} form-group" ng-repeat="row in messages">{{row.message}}</label>'+
                    '</div>'+

                    '<div ng-if="!saving">'+
                        '<div class="row">'+
                            '<div class="col-md-12 form-group">'+
                                '<div ng-if="withoutControls" class="alert alert-danger text-center" role="alert">'+
                                    'Não existem controles disponíveis para compartilhar'+
                                '</div>'+
                            '</div>'+
                            '<div class="col-md-6 form-group">'+
                                '<label>Bloco: </label>'+
                                '<select class="form-control" ng-options="item as item.name for item in apartment_buildings" ng-model="invite.block" required="required">'+
                                '</select>'+
                            '</div>'+
                            '<div class="col-md-6 form-group">'+
                                '<label>Controles: </label>'+
                                '<div class="form-check form-check-inline">'+
                                    '<label class="checkbox-inline" ng-repeat="row in controls track by $index">'+
                                        '<input type="checkbox" ng-name="chkControls" ng-model="controls[$index].allow" ng-checked="controls[$index].allow"> '+
                                        '{{row.description}}'+
                                    '</label>'+
                                '</div>'+
                            '</div>'+
                        '</div>'+
                        '<div class="row">'+
                            '<div ng-if="invite.data.length == 0 && invite.block.id > 0 && !withoutControls" class="col-md-12 form-group">'+
                                '<div ng-if="csv.result.length == 0" class="alert alert-info text-center" role="alert">'+
                                    'Antes de prosseguir certifique-se que esteja selecionando um arquivo do tipo <strong>CSV</strong>.<br>'+
                                    '<a target="_self" ng-href="img/templateConvites.csv" download="templateConvites.csv">Caso necessário faça download do model aqui</a>'+
                                '</div>'+
                                '<label>Selecione o arquivo: </label>'+
                                '<ng-csv-import content="csv.content"'+
                                    ' class="import"'+
                                    ' header="csv.header"'+
                                    ' header-visible="csv.headerVisible"'+
                                	' header="csv.header"'+
                                	' separator="csv.separator"'+
                                    ' result="csv.result"'+
                                	' accept="csv.accept"'+
                                    ' callback="csv.callback"'+
                                    ' encoding="csv.encoding"'+
                                    ' upload-button-label="csv.uploadButtonLabel"></ng-csv-import>'+
                            '</div>'+
                            '<div ng-if="invite.data.length > 0" class="col-md-12 form-group">'+
                                '<table class="table">'+
                                    '<thead>'+
                                        '<tr>'+
                                            '<th scope="col">Nome: </th>'+
                                            '<th scope="col">Email: </th>'+
                                            '<th scope="col">Unidade: </th>'+
                                            '<th></th>'+
                                        '</tr>'+
                                    '</thead>'+
                                    '<tbody>'+
                                        '<tr ng-repeat="row in invite.data track by $index">'+
                                            '<td show-erros>'+
                                                '<input name="name" id="name" type="text" ng-model="row.name" class="form-control" required="required"/>'+
                                                '<span ng-show="form_step.$submitted && form_step.name.$error.required" class="help-block ng-hide"><small>Campo obrigatório</small></span>'+
                                            '</td>'+
                                            '<td show-erros>'+
                                                '<input type="email" ng-model="row.email" class="form-control" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$" required="required"/>'+
                                                '<span ng-show="form_step.$submitted && form_step.name.$error.required" class="help-block ng-hide"><small>Campo obrigatório</small></span>'+
                                            '</td>'+
                                            '<td show-erros>'+
                                                '<select class="form-control" name="unit" ng-options="item.id as item.number for item in units" ng-model="row.unit" required="required" ng-valid="row.unid">'+
                                                    '<option value="">Selecione</option>'+
                                                '</select>'+
                                                '<span ng-show="form_step.$submitted && form_step.unit.$error.required" class="help-block ng-hide"><small>Campo obrigatório</small></span>'+
                                            '</td>'+
                                            '<td><i class="fa fa-trash" ng-click="removeInvite($index)" /></td>'+
                                        '</tr>'+
                                    '</tbody>'+
                                '</table>'+
                            '</div>'+
                        '</div>'+
                    '</div>'+
                '</div>' +

                '<div class="modal-footer">' +
                '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
                '    <button class="btn btn-success" type="submit" ng-disabled="form_step.$invalid || invite.data.length == 0 || controls.length == 0" ng-click="confirm()" >{{success_text}}</button>' +
                '</div>'+
            '</div>'+
        '</form>'
    );

    $templateCache.put('geolocation.modal.html',
        '<div class="modal-body text-center">' +
        '   <img class="geolocation" src="img/permission-geo.png" />' +
        '   <p class="padding">{{text}}</p>' +
        '</div>' +
        '<div class="modal-footer">' +
        '    <button class="btn btn-link btn-info btn-lg" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '    <button class="btn btn-success btn-lg" type="button" ng-click="confirm()" ng-disabled="waiting">'+
        '        <span ng-if="!waiting">{{success_text}}</span>'+
        '        <span ng-if="waiting"><i class="fa fa-circle-o-notch fa-spin"></i> Aguarde</span>'+
        '    </button>' +
        '</div>'
    );

    $templateCache.put('clientManagers.modal.html',
            '<div class="modal-header">'+
                '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
                '<h4 class="modal-title text-center">{{title}}</h4>'+
            '</div>'+

            '<div class="modal-body financial">' +
            '   <form name="form">' +
            '       <div class="row">' +
            '           <div class="col-md-6 form-group">' +
            '               <input type="text" ng-model="manager.name" class="form-control" placeholder="Nome"/>' +
            '           </div>' +
            '           <div class="col-md-6 form-group" ng-class="{\'has-error\' : duplicate}">' +
            '               <input type="email" ng-model="manager.email" class="form-control" placeholder="Email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,100}$" ng-change="duplicate = false"/>' +
            '               <span ng-if="duplicate" class="help-block"><small>Email já cadastrado</small></span>' +
            '           </div>' +
            '       </div>' +
            '   </form>' +
            '</div>' +

            '<div class="modal-footer">' +
            '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
            '    <button class="btn btn-success" type="button" ng-disabled="manager.name == \'\' || manager.email == \'\' || form.$invalid" ng-click="confirm()">{{success_text}}</button>' +
            '</div>'
    );

    $templateCache.put('clientAccess.modal.html',
            '<div class="modal-header">'+
                '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
                '<h4 class="modal-title text-center">{{title}}</h4>'+
            '</div>'+

            '<div class="modal-body financial">' +
            '   <form name="form">' +
            '       <div class="row">' +
            '           <div class="col-md-4 form-group">' +
            '               <input type="text" ng-model="access.description" class="form-control" placeholder="Nome"/>' +
            '           </div>' +
            '           <div class="col-md-4 form-group">' +
            '           <select class="form-control"  ng-model="access.type" required="required">'+
            '             <option value="">Tipo de Fechadura</option>'+
            '             <option value="eletroima">Eletroima</option>'+
            '             <option value="Solenoide">Solenoide</option>'+
            '             <option value="Fecho Eletrico">Fecho Elétrico</option>'+
            '             <option value="Fechadura Eletrica">Fechadura Elétrica</option>'+
            '             <option value="Catraca">Catraca</option>'+
            '             <option value="Cancela">Cancela</option>'+
            '             <option value="Geladeira">Geladeira</option>'+
            '             <option value="Portao Eletronico">Portão Eletronico</option>'+
            '             <option value="Carrinho">Carrinho de Compras</option>'+
            '             <option value="Outro">Outro</option>'+
            '           </select>'+
            '           </div>' +
            '           <div class="col-md-4 form-group">' +
            '               <input type="text" ng-model="access.color" class="form-control" placeholder="Cor"/>' +
            '           </div>' +
            '           <div class="col-md-4 form-group">' +
            '               <input type="text" ng-model="access.cam_id" class="form-control" placeholder="Camera ID"/>' +
            '           </div>' +
            '           <div class="col-md-4 form-group">' +
            '               <input type="text" ng-model="access.sensor_id" class="form-control" placeholder="Código do Sensor"/>' +
            '           </div>' +
            '           <div class="col-md-4 form-group">' +
            '               <input type="text" ng-model="access.stick_tag_code" class="form-control" placeholder="Código da Tag Adesiva"/>' +
            '           </div>' +

            '       </div>' +
            '   </form>' +
            '</div>' +

            '<div class="modal-footer">' +
            '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
            '    <button class="btn btn-success" type="button" ng-disabled="access.description == \'\'" ng-click="confirm()">{{success_text}}</button>' +
            '</div>'
    );

    $templateCache.put('addDeviceConfig.modal.html',
            '<div class="modal-header">'+
                '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
                '<h4 class="modal-title text-center">{{title}}</h4>'+
            '</div>'+

            '<div class="modal-body financial">' +
            '   <form name="form">' +
            '       <div class="row">' +
            '           <div class="col-md-4 form-group">' +
            '               <input type="number" ng-model="config.port" class="form-control" placeholder="Porta"/>' +
            '           </div>' +
            '           <div class="col-md-4 form-group">' +
            '               <input type="number" ng-model="config.time" class="form-control" placeholder="Tempo"/>' +
            '           </div>' +
            '           <div class="col-md-4 form-group">' +
            '       <div class="row">' +
            '               <input type="checkbox" ng-model="config.device_input" class="form-control"/>' +
            '               <label  for="inp"> Entrada?</label>'+
            '           </div>' +
            '           </div>' +
            '           <div class="col-md-4 form-group">' +
            '               <select class="form-control" ng-options="option.description for option in accesses track by option.id" ng-model="config.access_id"></select>' +
            '           </div>' +
            '       </div>' +
            '   </form>' +
            '</div>' +

            '<div class="modal-footer">' +
            '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
            '    <button class="btn btn-success" type="button" ng-disabled="config.port == \'\'  || !config.access_id.id" ng-click="confirm()">{{success_text}}</button>' +
            '</div>'
    );

    $templateCache.put('editDeviceConfig.modal.html',
            '<div class="modal-header">'+
                '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
                '<h4 class="modal-title text-center">{{title}}</h4>'+
            '</div>'+

            '<div class="modal-body financial">' +
            '   <form name="form">' +
            '       <div class="row">' +
            '           <div class="col-md-4 form-group">' +
            '               <input type="number" ng-model="config.port" class="form-control" placeholder="Porta"/>' +
            '           </div>' +
            '           <div class="col-md-4 form-group">' +
            '               <input type="number" ng-model="config.time" class="form-control" placeholder="Tempo"/>' +
            '           </div>' +
            '           <div class="col-md-4 form-group">' +
            '               <input type="checkbox" ng-model="config.input" class="form-control"/>' +
            '           </div>' +
            '           <div class="col-md-4 form-group">' +
            '               <select class="form-control" ng-options="option.description for option in accesses track by option.id" ng-model="config.access_id"></select>' +
            '           </div>' +
            '       </div>' +
            '   </form>' +
            '</div>' +

            '<div class="modal-footer">' +
            '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
            '    <button class="btn btn-success" type="button" ng-disabled="config.port == \'\' || config.time == \'\' || !config.access_id.id" ng-click="confirm()">{{success_text}}</button>' +
            '</div>'
    );

    $templateCache.put('confirmManager.modal.html',
        '<div class="modal-header">'+
            '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
            '<h4 class="modal-title text-center">{{title}}</h4>'+
        '</div>'+

        '<div class="modal-body financial">' +
            '<p class="alert alert-warning">Enviamos um email para {{email}} com o codigo de síndico!</p>' +
            '<form name="register_form">' +
                '<div class="form-group">' +
                    '<label>Codigo de síndico</label>' +
                    '<input type="text" class="form-control" ng-model="code" required>' +
                '</div>' +
            '</form>' +
        '</div>' +

        '<div class="modal-footer">' +
        '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '    <button class="btn btn-success" type="button" ng-disabled="!code" ng-click="confirm(code)">{{success_text}}</button>' +
        '</div>'
    );

    $templateCache.put('teste_signal.modal.html',
        '<div class="modal-header">'+
            '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
            '<h4 class="modal-title text-center">{{title}}</h4>'+
        '</div>'+

        '<div class="modal-body">' +
            '<div class="row" ng-if="signal.timer && ( !device.online || signal.wifi_quality < 40 || signal.wifi_latency > 1000 || signal.wifi_latency == 0 )">'+
                '<div class="col-md-12 form-group">'+
                    '<div class="alert alert-danger text-center" role="alert">'+
                        'Este dispositivo pode estar problemas de funcionamento'+
                    '</div>'+
                '</div>'+
            '</div>'+
            '<div class="row">'+
                '<ul class="list-group">'+
                    '<li class="list-group-item">'+
                        '<div class="row">'+
                            '<div class="col-md-10 form-group">'+
                                '<p>Conexão com dispositivo</p>'+
                            '</div>'+
                            '<div class="col-md-2 form-group">'+
                                '<i class="{{device.online ?  \'text-success fa fa-check\' : \'text-danger fa fa-close\'}}"></i>'+
                            '</div>'+
                        '</div>'+
                    '</li>'+
                    '<li class="list-group-item">'+
                        '<div class="row">'+
                            '<div class="col-md-10 form-group">'+
                                '<p>Conexão Wi-Fi</p>'+
                            '</div>'+
                            '<div class="col-md-2 form-group">'+
                                '<i class="{{signal.wifi_quality > 40 && ( signal.wifi_latency < 1000 || signal.wifi_latency == 0 ) ?  \'text-success fa fa-check\' : \'text-danger fa fa-close\'}}"></i>'+
                            '</div>'+
                        '</div>'+
                        '<div class="row">'+
                            '<div class="col-md-6 form-group">'+
                                '<p class="text-center"><b>Intensidade</b></p>'+
                                '<p class="text-center">acima de 40%</p>'+
                                '<p class="text-center" ng-if="signal.timer"><b class="{{signal.wifi_quality < 40 ? \'text-danger\' : \'text-success\'}}">{{signal.wifi_quality}}%</b> ({{signal.wifi_signal_strength}})</p>'+
                            '</div>'+
                            '<div class="col-md-6 form-group">'+
                                '<p class="text-center"><b>Latência</b></p>'+
                                '<p class="text-center">abaixo de 1000</p>'+
                                '<p class="text-center" ng-if="signal.timer"><b class="{{signal.wifi_latency > 1000 || signal.wifi_latency == 0 ? \'text-danger\' : \'text-success\'}}">{{signal.wifi_latency}}</b></p>'+
                            '</div>'+
                        '</div>'+
                    '</li>'+
                    '<li class="list-group-item" ng-if="signal.timer">'+
                        '<div class="row">'+
                            '<div class="col-md-10">'+
                                '<p>Porta 1 ({{port[1].description}})'+
                                '<button type="button" class="btn btn-default pull-right" ng-click="test(1)">Testar</button></p>'+
                            '</div>'+
                            '<div class="col-md-2">'+
                                '<i ng-if="port[1].tested" class="{{port[1].test_success ?  \'text-success fa fa-check\' : \'text-danger fa fa-close\'}}"></i>'+
                            '</div>'+
                        '</div>'+
                    '</li>'+
                    '<li class="list-group-item" ng-if="signal.timer">'+
                        '<div class="row">'+
                            '<div class="col-md-10">'+
                                '<p>Porta 2 ({{port[2].description}})'+
                                '<button type="button" class="btn btn-default pull-right" ng-click="test(2)">Testar</button></p>'+
                            '</div>'+
                            '<div class="col-md-2">'+
                                '<i ng-if="port[2].tested" class="{{port[2].test_success ?  \'text-success fa fa-check\' : \'text-danger fa fa-close\'}}"></i>'+
                            '</div>'+
                        '</div>'+
                    '</li>'+

                    '<li class="list-group-item" ng-if="signal.timer">'+
                        '<div class="row">'+
                            '<div class="col-md-10">'+
                                '<p>Porta 3 ({{port[3].description}})'+
                                '<button type="button" class="btn btn-default pull-right" ng-click="test(3)">Testar</button></p>'+
                            '</div>'+
                            '<div class="col-md-2">'+
                                '<i ng-if="port[3].tested" class="{{port[3].test_success ?  \'text-success fa fa-check\' : \'text-danger fa fa-close\'}}"></i>'+
                            '</div>'+
                        '</div>'+
                    '</li>'+

                    '<li class="list-group-item" ng-if="signal.timer">'+
                        '<div class="row">'+
                            '<div class="col-md-10">'+
                                '<p>Porta 4 ({{port[4].description}})'+
                                '<button type="button" class="btn btn-default pull-right" ng-click="test(4)">Testar</button></p>'+
                            '</div>'+
                            '<div class="col-md-2">'+
                                '<i ng-if="port[4].tested" class="{{port[4].test_success ?  \'text-success fa fa-check\' : \'text-danger fa fa-close\'}}"></i>'+
                            '</div>'+
                        '</div>'+
                    '</li>'+
                '</ul>'+
            '</div>'+
        '</div>' +

        '<div class="modal-footer">' +
        '    <button class="btn btn-success" type="button" ng-click="cancel()">{{ok_text}}</button>' +
        '</div>'
    );

    $templateCache.put('listTags.modal.html',
            '<div class="modal-header">'+
                '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
                '<h4 class="modal-title text-center">{{title}}</h4>'+
            '</div>'+

            '<div class="modal-body">' +

                '<table class="table">'+
                '    <thead>'+
                    '        <tr>'+
                    '            <th class="text-left">UID</th>'+
                    '            <th class="text-left">Tipo</th>'+
                    '        </tr>'+
                    '    </thead>'+
                '</table>'+
            '</div>'
    );

    $templateCache.put('editAccess.modal.html',
        '<div class="modal-body">' +

            '<label class="control-label text-left">Nome</label>' +
            '<input type="text" class="form-control" name="description" ng-model="access.description" />' +

            '<label class="control-label text-left">Tipo de Fechadura</label>' +
            '<select class="form-control" name="type" ng-model="access.type" required="required">'+
            '             <option value="">Selecione</option>'+
            '             <option value="eletroima">Eletroima</option>'+
            '             <option value="Solenoide">Solenoide</option>'+
            '             <option value="Fecho Eletrico">Fecho Elétrico</option>'+
            '             <option value="Fechadura Eletrica">Fechadura Elétrica</option>'+
            '             <option value="Catraca">Catraca</option>'+
            '             <option value="Cancela">Cancela</option>'+
            '             <option value="Geladeira">Geladeira</option>'+
            '             <option value="Portao Eletronico">Portão Eletronico</option>'+
            '             <option value="Carrinho">Carrinho de Compras</option>'+
            '             <option value="Outro">Outro</option>'+
            '</select>'+

            '<label class="control-label text-left">Lotacão Max</label>' +
            '<input type="text" class="form-control" name="max_occupants" ng-model="access.max_occupants" />' +

            // '<label class="control-label text-left">Código do Sensor</label>' +
            // '<input type="text" class="form-control" name="sensor_id" ng-model="access.sensor_id" />' +

            '<label class="control-label text-left">Cod Tag Adesiva Entrada</label>' +
            '<input type="text" class="form-control" name="stick_tag_code" ng-model="access.stick_tag_code" />' +
            '<label class="control-label text-left">Cod Tag Adesiva Saida</label>' +
            '<input type="text" class="form-control" name="stick_tag_code_exit" ng-model="access.stick_tag_code_exit" />' +
            '<label class="control-label text-left">QR Code Entrada</label>' +
            '<input type="text" class="form-control" name="stick_tag_qr_code" ng-model="access.stick_tag_qr_code" />' +
            '<label class="control-label text-left">QR Code Saida</label>' +
            '<input type="text" class="form-control" name="stick_tag_qr_code_exit" ng-model="access.stick_tag_qr_code_exit" />' +

        '</div>' +
        '<div class="modal-footer">' +
        '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">Cancelar</button>' +
        '    <button class="btn btn-success" type="button" ng-click="save()">Salvar</button>' +
        '</div>'
    );

    $templateCache.put('editUnitAccess.modal.html',
    '<div class="modal-body">' +

        '<label class="control-label text-left">Acessos Disponíveis</label>' +
        '<div class="form-check ">' +
        '<div class = "row" ng-repeat="access in availableAccesses track by $index">'+
        '<label class="checkbox-inline">'+
        '<input type="checkbox" ng-model="availableAccesses[$index].selected" ng-checked="availableAccesses[$index].selected"> '+
        '{{access.description}}'+
        '</label>' +
        '</div>' +
        '</div>' +
    '</div>' +
    '<div class="modal-footer">' +
    '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">Cancelar</button>' +
    '    <button class="btn btn-success" type="button" ng-click="save()">Salvar</button>' +
    '</div>'
);

    $templateCache.put('notify.modal.html',
        '<div class="modal-header">'+
            '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
            '<h4 class="modal-title text-center">{{title}}</h4>'+
        '</div>'+

        '<div class="modal-body">' +
            '<form name="form">' +
            '<div class="row">'+
                '<div class="col-md-12">'+
                    '<label class="control-label text-left">Filtro por acesso</label>'+
                    '<select class="form-control" name="access_id" ng-model="access_id" >'+
                        '<option ng-repeat="access in accesses" value="{{access.id}}">{{access.description}}</option>'+
                    '</select>'+
                    '</div>'+
            '</div>'+
                '<div class="row">'+
                    '<div class="col-md-8">'+
                        '<label class="control-label">Notificação</label>'+
                        '<input type="text" ng-model="notification.text" class="form-control" style="background-color: #fff; border: #cecece 1px solid; color:#000" required>'+
                        
                    '</div>'+
                '</div>'+
            '</form>'+
        '</div>' +

        '<div class="modal-footer">' +
        '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '    <button class="btn btn-success" type="button" ng-disabled="form.$invalid" ng-click="confirm(code)">{{success_text}}</button>' +
        '</div>'
    );

    $templateCache.put('showUser.modal.html',
        '<div class="modal-header">'+
            '<button type="button" class="close" ng-click="cancel()"><span aria-hidden="true">&times;</span></button>'+
            '<h4 class="modal-title text-center">{{title}}</h4>'+
        '</div>'+

        '<div class="modal-body">' +
        '<div class="row">' +
        '   <div class="col-md-12">' +
        '     <div class="image-container">' +
        '       <img ng-if="user.avatar" class="user_avatar_modal" ng-src="{{user.avatar}}"></img>'+
        '       <img ng-if="!user.avatar" class="user_avatar_modal" src="img/avatar.gif"></img>'+
        '     </div>' +
        '   </div>' +
        '</div>' +
        '   <form name="form">' +
        '       <div class="row">' +
        '           <div class="col-md-12 text-center">' +
        '               <label >{{user.name}}</label>' +
        '           </div>' +
        '           <div class="col-md-12 text-center">' +
        '               <label >{{user.email}}</label>' +
        '           </div>' +
        '           <div class="col-md-12 text-center">' +
        '               <label >{{user.phones}}</label>' +
        '           </div>' +
        '       </div>' +
        '       <div class="row">' +
        '           <div class="col-md-12 form-group">' +
        '               <label class="control-label">Notificação</label>'+
        '               <input type="text" ng-model="notification.text" class="form-control" style="background-color: #fff; border: #cecece 1px solid; color:#000" required>'+
        '           </div>' +
        '       </div>' +
        '       <div class="row">' +
        '           <div class="col-md-12 form-group">' +
        '               <label class="control-label">&nbsp;</label>'+
        '               <button class="btn btn-primary" ng-click="notify()">Notificar</button>'+
        '           </div>' +
        '       </div>' +
        '       <div class="row">' +
        '           <div class="col-md-12 form-group">' +
        '               <table class="table">'+
        '                   <tr>'+
        '                       <th>Acessos</th>'+
        '                       <th></th>'+
        '                   </tr>'+
        '                   <tr ng-repeat="device in user.devices">'+
        '                       <td>{{device.nickname}}</td>'+
        '                       <td><i class="fa fa-trash" uib-tooltip="Remover" ng-click="removeDevice(device)"></i></td>'+
        '                   </tr>'+
        '               </table>'+
        '           </div>' +
        '       </div>' +
        '   </form>' +
        '</div>'
    );
}]);
