angular.module('accesses', ['ui.router','confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap', 'toggle-switch']);
angular.module('accesses').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

    $stateProvider

    .state('accesses', {
        url         : "/accesses",
        parent: 'app.user',
        views: {
            'content':{
                templateUrl : server+"/views/m/accesses",
                controller  : 'accesses.list',
            }
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Acessos'
        }
    })

    .state('access_show', {
        url         : "/accesses/:id",
        parent: 'app.user',
        views: {
            'content':{
                templateUrl : server+"/views/m/accesses/show",
                controller  : 'accesses.show',
            }
        },
        ncyBreadcrumb: {
            parent: 'accesses',
            label: '{{accesses.description}}'
        }
    })

}]);

/**
 * List Controller
 */
angular.module('accesses').
controller('accesses.list', ['$scope', '$http', '$auth', '$q', 'NgTableParams','$client_access', function($scope, $http, $auth, $q, NgTableParams,$client_access) {
    $scope.tableParams = new NgTableParams({
        page: 1,
        count: 10,
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            console.log(params)
            return $client_access.query(params.url()).$promise.then(function(data) {
                params.total(data.total);
                return data.data;
            });
        }
    });
}]);

angular.module('accesses').
controller('accesses.show', ['$scope', '$http', '$auth', '$q', 'NgTableParams','$client_access','$stateParams','$task', function($scope, $http, $auth, $q, NgTableParams,$client_access, $stateParams, $task) {
    $client_access.get({id:$stateParams.id}).$promise.then(function(response){
      $scope.access=response;
    })

    $scope.tableParams = new NgTableParams({
        page: 1,
        count: 10,
        filter: {
          client_access_id:$stateParams.id
        }
    }, {
        counts: [],
        filterDelay: 0,

        getData: function(params) {
            return $task.query(params.url()).$promise.then(function(data) {
              console.log(data);
                params.total(data.total);
                return data.data;
            });
        }
    });
}]);

angular.module('accesses').
factory("$client_access", [ '$resource', '$q', '$http',
function($resource,$q,$http) {
    var resource = $resource(api+'client_access/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        }
    });

    return resource;
}]);
