angular.module('accounts_plan', ['ui.router','confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap', 'toggle-switch']);

angular.module('accounts_plan').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

    $stateProvider
    .state('accounts_plan', {
        url         : "/accounts_plan",
        parent: 'app.admin',
        views: {
            'content':{
                templateUrl : server+"/views/m/accounts_plan",
                controller  : 'ap.list',
            }
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'plano de contas'
        }
    })

    .state('accounts_plan_create', {
        url         : "/accounts_plan/create",
        parent: 'app.admin',
        views: {
            'content':{
                templateUrl : server+"/views/m/accounts_plan/create",
                controller  : 'ap.create',
            }
        },
        ncyBreadcrumb: {
            parent: 'accounts_plan',
            label: '{{ap.display_name}}'
        }
    })

    .state('accounts_plan_show', {
        url         : "/accounts_plan/:id",
        parent: 'app.admin',
        views: {
            'content':{
                templateUrl : server+"/views/m/accounts_plan/show",
                controller  : 'ap.show',
            }
        },
        ncyBreadcrumb: {
            parent: 'accounts_plan',
            label: '{{ap.display_name}}'
        }
    })

}]);

/**
 * List Controller
 */
angular.module('accounts_plan').
controller('ap.list', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$accounts_plan', function($scope, $http, $auth, $q, NgTableParams, $accounts_plan) {
    $scope.tableParams = new NgTableParams({
        page: 1,
        count: 10
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $accounts_plan.query(params.url()).$promise.then(function(data) {
                params.total(data.total);
                return data.data;
            });
        }
    });
}]);

/**
 * Create Controller
 */
angular.module('accounts_plan').
controller('ap.create', ['$scope', '$state', '$accounts_plan', 'NgTableParams', '$permission', function($scope, $state, $accounts_plan, NgTableParams, $permission) {

    $scope.tableParams = new NgTableParams({
        page: 1,
        count: 10
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $permission.query(params.url()).$promise.then(function(data) {
                params.total(data.total);
                return data.data;
            });
        }
    });

    $scope.save = function() {
        $accounts_plan.save($scope.role).$promise.then(function(data) {
            $state.go('accounts_plan_show', {
                id: data.id
            });
        });
    }
}]);

/**
 * Show, Edit, Delete Controller
 */
angular.module('accounts_plan').
controller('ap.show', ['$state', '$scope', '$stateParams', '$uibModal', '$accounts_plan', '$permission', 'NgTableParams', function($state, $scope, $stateParams, $uibModal, $accounts_plan, $permission, NgTableParams) {

    $scope.editing = false;

    $scope.toggleEdit = function() {
        $scope.editing = !$scope.editing;
    }

    $scope.tableParams = new NgTableParams({
        page: 1,
        count: 10,
        role: $stateParams.id
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $permission.query(params.url()).$promise.then(function(data) {
                params.total(data.total);
                return data.data;
            });
        }
    });

    $accounts_plan.get({
        id: $stateParams.id
    }).$promise.then(function(data) {
        $scope.role = data;
    }, function(error) {
        if (error.status == 404) {
            $state.go('notFound');
        }
    });

    $scope.confirmUpdate = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$accounts_plan', function($scope, $uibModalInstance, $accounts_plan) {

                $scope.title = 'Deseja atualizar?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Concluído';

                var role = $scope.$parent.role;

                $scope.confirm = function() {
                    $accounts_plan.update({
                        id: ap.id
                    }, role).$promise.then(function(data) {
                        $uibModalInstance.dismiss('cancel');
                        $scope.$parent.toggleEdit();
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

    $scope.confirmDelete = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$accounts_plan', function($state, $scope, $uibModalInstance, $accounts_plan) {

                $scope.title = 'Deseja remover esse registro?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                var role = $scope.$parent.role;

                $scope.confirm = function() {
                    $accounts_plan.delete({id:ap.id}).$promise.then(function(data){
                        $uibModalInstance.dismiss('cancel');
                        $state.go('accounts_plan');
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

}]);


/**
 * Factory
 */
angular.module('accounts_plan').
factory("$accounts_plan", [ '$resource', function($resource) {
    var resource = $resource(api + 'role/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        }
    });
    return resource;
}]);

angular.module('accounts_plan').
factory("$permission", [ '$resource', function($resource) {
    var resource = $resource(api + 'permission/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        }
    });
    return resource;
}]);


angular.module("confirm.modal.html", []).run(["$templateCache", function($templateCache) {
    $templateCache.put('confirm.modal.html',
        '<div class="modal-body">' +
        ' {{title}}' +
        '</div>' +
        '<div class="modal-footer">' +
        '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '    <button class="btn btn-outline btn-success" type="button" ng-click="confirm()">{{success_text}}</button>' +
        '</div>'
    );
}]);
