angular.module('invite', ['ui.router','confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap']);

angular.module('invite').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

    $stateProvider
    .state('invite', {
        url         : "/invite",
        parent: 'app.admin',
        views: {
            'content':{
                templateUrl : server+"/views/m/invite",
                controller  : 'invite.list',
            }
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Invite'
        }
    })

    .state('invite_create', {
        url         : "/invite/create",
        parent: 'app.admin',
        views: {
            'content':{
                templateUrl : server+"/views/m/invite/create",
                controller  : 'invite.create',
            }
        },
        ncyBreadcrumb: {
            parent: 'invite',
            label: 'Adicionar'
        }
    })

    .state('invite_show', {
        url         : "/invite/:id",
        parent: 'app.admin',
        views: {
            'content':{
                templateUrl : server+"/views/m/invite/show",
                controller  : 'invite.show',
            }
        },
        ncyBreadcrumb: {
            parent: 'invite',
            label: '{{invite.id}}'
        }
    })

}]);

/**
 * List Controller
 */
angular.module('invite').
controller('invite.list', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$invite', function($scope, $http, $auth, $q, NgTableParams, $invite) {
    $scope.tableParams = new NgTableParams({
        page: 1,
        count: 10
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $invite.query(params.url()).$promise.then(function(data) {
                params.total(data.total);
                return data.data;
            });
        }
    });
}]);



/**
 * Show, Edit, Delete Controller
 */
angular.module('invite').
controller('invite.show', ['$state', '$scope', '$stateParams', '$uibModal', '$invite', function($state, $scope, $stateParams, $uibModal, $invite) {

    $scope.editing = false;

    $scope.toggleEdit = function() {
        $scope.editing = !$scope.editing;
    }

    $invite.get({
        id: $stateParams.id
    }).$promise.then(function(data) {
        $scope.invite = data;
    }, function(error) {
        if (error.status == 404) {
            $state.go('notFound');
        }
    });

    $scope.confirmUpdate = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$invite', function($scope, $uibModalInstance, $invite) {

                $scope.title = 'Deseja atualizar?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Concluído';

                var invite = $scope.$parent.invite;

                $scope.confirm = function() {
                    $invite.update({
                        id: invite.id
                    }, invite).$promise.then(function(data) {
                        $uibModalInstance.dismiss('cancel');
                        $scope.$parent.toggleEdit();
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

    $scope.confirmDelete = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$invite', function($state, $scope, $uibModalInstance, $invite) {

                $scope.title = 'Deseja remover esse registro?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                var invite = $scope.$parent.invite;

                $scope.confirm = function() {
                    $invite.delete({id:invite.id}).$promise.then(function(data){
                        $uibModalInstance.dismiss('cancel');
                        $state.go('invite');
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

}]);


/**
 * Factory
 */
angular.module('invite').
factory("$invite", ['$resource','$q', '$http', function($resource, $q, $http) {
    var resource = $resource(api + 'invite/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        }
    });

    resource.resend = function(id){
        var deferred = $q.defer();
        $http.get(api+'invite/'+id+'/resend').then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.activate = function(id){
        var deferred = $q.defer();
        $http.get(api+'invite/'+id+'/activate').then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    return resource;
}]);

angular.module("confirm.modal.html", []).run(["$templateCache", function($templateCache) {
    $templateCache.put('confirm.modal.html',
        '<div class="modal-body">' +
        ' {{title}}' +
        '</div>' +
        '<div class="modal-footer">' +
        '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '    <button class="btn btn-outline btn-success" type="button" ng-click="confirm()">{{success_text}}</button>' +
        '</div>'
    );
}]);
