angular.module('permission', ['ui.router','confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap', 'toggle-switch']);

angular.module('permission').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

    $stateProvider

    .state('permission', {
        url         : "/permission",
        parent: 'app.user',
        views: {
            'content':{
                templateUrl : server+"/views/m/permission",
                controller  : 'permission.list',
            }
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Permissões'
        }
    })

    .state('permission_show', {
        url         : "/permission/:id",
        parent: 'app.user',
        views: {
            'content':{
                templateUrl : server+"/views/m/permission/show",
                controller  : 'permission.show',
            }
        },
        ncyBreadcrumb: {
            parent: 'permission',
            label: '{{permission.hash}}'
        }
    })

}]);

/**
 * List Controller
 */
angular.module('permission').
controller('permission.list', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$permission', '$invite', '$client', '$invite', '$uibModal', 'ngToast', 'ngIntroService', '$localStorage', '$user', '$client_manager',
    function($scope, $http, $auth, $q, NgTableParams, $permission, $invite, $client, $invite, $uibModal, ngToast, ngIntroService, $localStorage, $user, $client_manager) {

    $scope.hidePermissions  = false;
    $scope.hideInvites      = false;
    $scope.finish_loading = false;

    $scope.loadClients = function()
    {
        $client_manager.getAll().then(function(response){
            $scope.managing = response;
            if( $scope.managing.length > 0 ){
                // Abrir modal para confirmar gestor
                var unvalidates = _.where($scope.managing, {confirm_date : null});
                if( unvalidates.length > 0 ){
                    _.each( unvalidates, function(unvalidate){
                        var modalInstance = $uibModal.open({
                            animation      : true,
                            ariaLabelledBy : 'modal-title',
                            ariaDescribedBy: 'modal-body',
                            templateUrl    : 'confirmManager.modal.html',
                            controller     : 'confirm_manager',
                            size           : 'md',
                            resolve        :  {
                                manager: function(){
                                    return angular.copy(unvalidate)
                                }
                            }
                        });

                        modalInstance.result.then(function() {
                            $scope.loadClients();
                        });
                    });
                }

                // Eu pego os usuários por dispositivo
                $scope.devices = [];
                var calls = [];
                _.each($scope.managing, function(managing){
                    calls.push($client.getDevices(managing.client.id).then(function(response){
                        $scope.total_devices;
                        for(var i = 0; i < response.length; i++){
                            if(response[i].device && response[i].device.status == 1 ){
                                $scope.devices.push(response[i].device);
                            }
                        }
                    }));
                });

                $q.all(calls).then(function(){
                    $scope.finish_loading = true;
                });

                $permission.query().$promise.then(function(data) {
                    $scope.permissions = data;
                    if( data.length == 0 ){
                        $scope.hidePermissions = true;
                    }else{
                        $scope.hidePermissions = false;
                    }
                });

            } else {
                $scope.withoutControls = true;
            }
        });
    }
    $scope.loadClients();

    setTimeout(function () {
        $scope.IntroOptions = {
            steps:[
                {
                    intro: 'Bem vindo ao Noknox Doorman'
                },
                {
                    element: document.querySelector('#step1'),
                    intro: "Aqui você pode gerenciar as permissões dos moradores"
                },
                {
                    element: document.querySelector('#step2'),
                    intro: "Nesta aba você gerencia os convites"
                },
                {
                    element: document.querySelector('#step3'),
                    intro: "Você pode importar uma lista de moradores (.CSV)"
                },
                {
                    element: document.querySelector('#step4'),
                    intro: "Ou convidar um a um dos moradores"
                }
            ],
            showStepNumbers: false,
            showBullets: false,
            exitOnOverlayClick: false,
            exitOnEsc:  true,
            nextLabel: '<span style="color:green">Proximo</span>',
            prevLabel: 'Anterior',
            skipLabel: 'Sair',
            doneLabel: 'Ok'

        };

        ngIntroService.setOptions($scope.IntroOptions);
        if($localStorage.user.first_access){
            ngIntroService.start();
            $user.updateProfile({first_access: false}).then(function(response){
                $localStorage.user.first_access = false;
            });
        }
    }, 100);

    $scope.tableInvites = new NgTableParams({
        page: 1,
        count: 10
    }, {
        counts: [],
        filterDelay: 10,
        getData: function(params) {
            var result = $invite.query(params.url()).$promise.then(function(data) {
                if( data.total == 0 && !params.hasFilter() ){
                    $scope.hideInvites = true;
                }else{
                    $scope.hideInvites = false;
                }
                params.total(data.total);
                return data.data;
            }, function(error){
                $scope.hideInvites = true;
                params.total(0);
                return [];
            });

            return result;
        }
    });

    $scope.deleteInvite = function(id)
    {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$device', function($state, $scope, $uibModalInstance, $device) {

                $scope.title = 'Deseja remover esse convite?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                var device = $scope.$parent.device;

                $scope.confirm = function() {
                    $invite.delete({id:id}).$promise.then(function(response){
                        $scope.tableInvites.reload();
                        $scope.toast('Convite apagado!', false);
                    }, function(error) {
                        $scope.toast('Erro ao apagar convite!', true);
                    });

                    $uibModalInstance.dismiss('cancel');
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.resendInvite = function(id)
    {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$device', function($state, $scope, $uibModalInstance, $device) {

                $scope.title = 'Deseja reenviar este convite?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                var device = $scope.$parent.device;

                $scope.confirm = function() {
                    $invite.resend(id).then(function(response){
                        $scope.toast('Convite enviado!', false);
                    }, function(error) {
                        $scope.toast('Erro ao enviar convite!', true);
                    });

                    $uibModalInstance.dismiss('cancel');
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.activateInvite = function(id)
    {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$device', function($state, $scope, $uibModalInstance, $device) {

                $scope.title = 'Forçar a ativação desse convite?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                var device = $scope.$parent.device;

                $scope.confirm = function() {
                    $invite.activate(id).then(function(response){
                        $scope.toast('Controles ativados!', false);
                        $scope.tableInvites.reload();
                    }, function(error) {
                        $scope.toast('Usuário ainda não se cadastrou na plataforma.', true);
                    });

                    $uibModalInstance.dismiss('cancel');
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.addNewInvite = function()
    {
        var modalInstance = $uibModal.open({
            animation      : true,
            ariaLabelledBy : 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl    : 'newInvite.modal.html',
            controller     : 'invite.create',
            size           : 'lg',
        });

        modalInstance.result.then(function() {
            $scope.tableInvites.reload();
        });
    }

    $scope.importCSV = function()
    {
        var modalInstance = $uibModal.open({
            animation      : true,
            ariaLabelledBy : 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl    : 'importCSV.modal.html',
            controller     : 'import',
            size           : 'lg',
        });

        modalInstance.result.then(function() {
            $scope.tableInvites.reload();
        });
    }

    $scope.toast = function(msg, error)
    {
        if(error) {
            ngToast.danger({
                content: msg,
                dismissOnTimeout: true,
                timeout: 3000
            });
        } else {
            ngToast.success({
                content: msg,
                dismissOnTimeout: true,
                timeout: 3000
            });
        }
    }
}]);

angular.module('permission').
controller('permission.show', ['$state', '$scope', '$stateParams', '$uibModal', '$location', '$user_device', '$device',function($state, $scope, $stateParams, $uibModal, $location, $user_device, $device) {

    $scope.editing = false;
    $scope.toggleEdit = function() {
        $scope.editing = !$scope.editing;
    }

    // Procurar se há alguma permissão pro usuário
    $scope.loadPermission = function()
    {
        $user_device.query({id: $stateParams.id}).$promise.then(function(data) {
            $scope.permission = data[0];
            // Pegar todos os dispositivos que eu sou administrador e remover os duplicados
            $device.iAmOwner().then(function(response){
                $scope.devices = [];
                _.each(response, function(access){
                    if(_.findWhere($scope.permission.devices, {client_access_id: access.id}) == undefined)
                    {
                        $scope.devices.push(access);
                    }
                });
            });
        });
    }
    $scope.loadPermission();

    $scope.confirmDelete = function(id) {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$permission', '$user_device',
            function($state, $scope, $uibModalInstance, $permission, $user_device) {

                $scope.title = 'Deseja remover esse registro?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                $scope.confirm = function() {
                    $user_device.delete({id:id}).$promise.then(function(data){
                        $uibModalInstance.close(data);
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });

        modalInstance.result.then(function() {
            $scope.loadPermission();
        });
    };

    $scope.createAccess = function(row)
    {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$permission', '$user_device',
            function($state, $scope, $uibModalInstance, $permission, $user_device) {

                $scope.title = 'Deseja permitir acesso desse usuário?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                $scope.confirm = function() {

                    var data = {
                        serial:row.device.hash,
                        access_id: row.id,
                        nickname: row.description,
                        role: 'user',
                        color: row.color,
                        user_id: $stateParams.id
                    };

                    $user_device.save(data).$promise.then(function(data){
                        $uibModalInstance.close(data);
                        $scope.loadPermission();
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

}]);

angular.module('permission')
.controller('import', ['$scope', '$http', '$auth', '$q', '$uibModal', '$uibModalInstance', '$permission', '$client', '$invite', '$device', '$client_manager', '$client_access',function($scope, $http, $auth, $q, $uibModal, $uibModalInstance, $permission, $client, $invite, $device, $client_manager, $client_access ) {
        $scope.title                = 'Importação CSV';
        $scope.cancel_text          = 'Cancelar';
        $scope.success_text         = 'Concluído';
        $scope.saving               = false;
        $scope.withoutControls      = false;
        $scope.progress             = 0;
        $scope.sent                 = 0;
        $scope.controls             = [];
        $scope.apartment_buildings  = [];
        $scope.messages             = [];
        $scope.invite               = {
            block : {},
            controls: {},
            data : []
        };
        var ua = navigator.userAgent.toLowerCase()
        if( ua.indexOf('windows') != -1 ){
            $scope.csv = {
                content             : null,
                header              : false,
                headerVisible       : true,
                accept              : '.csv',
                separator           : ';',
                result              : [],
                encoding            : 'ISO-8859-1',
                uploadButtonLabel   : "upload a csv file"
            };
        }else{
            $scope.csv = {
                content             : null,
                header              : false,
                headerVisible       : true,
                accept              : '.csv',
                separator           : ';',
                result              : [],
                encoding            : 'UTF-8',
                uploadButtonLabel   : "upload a csv file"
            };
        }

        $scope.loadClients = function()
        {
            $client_manager.getAll().then(function(response){
                $scope.managing = response;
                if( $scope.managing.length > 0 ){
                    _.each($scope.managing, function(managing){
                        _.each(managing.client.buildings, function(block){
                            $scope.apartment_buildings.push(block);
                        });
                        $client_access.get({ 'filter[client_id]' : managing.client.id}).$promise.then(function(response){
                            _.each(response.data, function(client_access){
                                if( client_access.config ){
                                    $scope.controls.push({
                                        id                  : client_access.id,
                                        client_access_id    : client_access.id,
                                        description         : client_access.description,
                                        color               : client_access.color,
                                        allow               : true
                                    });
                                }
                            });
                        });
                    });

                } else {
                    $scope.withoutControls = true;
                }
            });
        }
        $scope.loadClients();

        $scope.$watch('invite.block.id', function(){
            if( $scope.invite.block.id ){
                $client.getUnits($scope.invite.block.client_id, $scope.invite.block.id).then(function(response){
                    $scope.units = response;
                });

            }
        });

        $scope.$watch('csv.result', function(){
            if( $scope.csv.result.length > 0 ){
                for( var i = 1; i < $scope.csv.result.length; i++ ){
                    var unit  = null;
                    var index = _.findIndex($scope.units, {number: $scope.csv.result[i][2]});
                    if( index > -1 ){
                        unit = $scope.units[index].id;
                    }
                    $scope.invite.data.push({
                        name            : $scope.csv.result[i][0],
                        email           : $scope.csv.result[i][1],
                        unit            : unit
                    });
                }
            }
        });

        $scope.removeInvite = function(index)
        {
            $scope.invite.data.splice(index,1);
        }

        $scope.confirm = function()
        {

            if( $scope.saving ){
                $uibModalInstance.close($scope.invite);
                return;
            }else{
                $scope.saving       = true;
                $scope.progress     = 0;
            }

            var progress = 100 / $scope.invite.data.length;
            var invite   = {};
            var controls = [];

            progress = parseFloat(progress.toFixed(2));
            $scope.progress = parseFloat(progress);

            for( var x = 0; x < $scope.controls.length; x++ )
            {
                if( $scope.controls[x].allow )
                {
                    controls.push({
                        id          : $scope.controls[x].id,
                        description : $scope.controls[x].description,
                        color       : $scope.controls[x].color
                    });
                }
            }

            for( var i = 0; i < $scope.invite.data.length; i++ )
            {
                invite = {
                    controls        : JSON.stringify( controls ),
                    client_id       : $scope.invite.block.client_id,
                    client_unit_id  : $scope.invite.data[i].unit,
                    name            : $scope.invite.data[i].name,
                    email           : $scope.invite.data[i].email
                };
                $invite.save(invite).$promise.then(function(data){
                    $scope.sent = parseInt($scope.sent) + 1;
                    if( $scope.sent == $scope.invite.data.length - 1 ){
                        $scope.messages.push({
                            type: 'success',
                            message: 'Envio de email concluídos!'
                        });
                    } else {
                        $scope.progress = $scope.progress + parseFloat(progress);
                    }
                }, function(error) {
                    $scope.messages.push({
                        type: 'danger',
                        message: 'Não foi possível enviar convite para '+$scope.invite.data[i].name
                    });
                });
            }
        };

        $scope.cancel = function()
        {
            $uibModalInstance.dismiss('cancel');
        };
}]);

angular.module('permission')
.controller('invite.create', ['$scope', '$http', '$auth', '$q', '$uibModal', '$uibModalInstance', '$permission', '$client', '$invite', 'ngToast', '$device', '_', '$client_manager', '$client_access', 'client_id',
function($scope, $http, $auth, $q, $uibModal, $uibModalInstance, $permission, $client, $invite, ngToast, $device, _, $client_manager, $client_access, client_id ) {
        $scope.title                = 'Convidar';
        $scope.cancel_text          = 'Cancelar';
        $scope.success_text         = 'Concluído';
        $scope.controls             = [];
        $scope.apartment_buildings  = [];
        $scope.invite               = {
            controls: [],
            selectedDays:[],
            limitedAccess:false

        };

        $scope.start_at = "";
        $scope.end_at = "";
        $scope.start_at2 = "";
        $scope.end_at2 = "";
        $scope.start_at3 = "";
        $scope.end_at3 = "";
       

        $scope.daysOfWeek = [   {value:'sun',label:'Dom',selected:false},
                                {value:'mon',label:'Seg',selected:false},
                                {value:'tue',label:'Ter',selected:false},
                                {value:'wed',label:'Qua',selected:false},
                                {value:'thu',label:'Qui',selected:false},
                                {value:'fri',label:'Sex',selected:false},
                                {value:'sat',label:'Sab',selected:false},
                                ];

        $scope.controlsConfig = {
            buttonClasses: 'form-control',
            scrollable: true,
            scrollableHeight: '200px',
            enableSearch: false,
            styleActive: true,
            showCheckAll: true,
            showUncheckAll: true,
            displayProp: 'description',
            idProp: 'port',
            closeOnSelect: false,
            selectionLimit: 0,
        }

        $scope.dropdownTranslation = {
            buttonDefaultText: 'Selecione',
            checkAll: 'Todos',
            uncheckAll: 'Nenhum',
            selectionCount: 'selecionado(s)',
            selectionOf: '/',
            searchPlaceholder: 'Pesquisar',
            dynamicButtonTextSuffix: 'selecionado(s)'
        };

        $scope.$watch('invite.block.id', function(){
            if( $scope.invite.block ){
                $client.getUnits($scope.invite.block.client_id, $scope.invite.block.id).then(function(response){
                    $scope.units = response;
                });
            }
        });

        $scope.loadClients = function()
        {
            $client.get({id:client_id}).$promise.then(function(response){
                $scope.apartment_buildings = response.buildings;
            });

            $client_access.get({ 'filter[client_id]' : client_id}).$promise.then(function(response){
                _.each(response.data, function(client_access){
                    if( client_access.config ){
                        $scope.controls.push({
                            id                  : client_access.id,
                            client_access_id    : client_access.id,
                            description         : client_access.description,
                            color               : client_access.color,
                            allow               : true
                        });
                    }
                });
            });
        }
        $scope.loadClients();

        $scope.selectDay=function(index){
            if($scope.daysOfWeek[index].selected)
             $scope.daysOfWeek[index].selected = false;
            else
             $scope.daysOfWeek[index].selected = true;
        }

        $scope.confirm = function()
        {
            var invite   = {};
            var controls = [];
            for( var x = 0; x < $scope.controls.length; x++ )
            {
                if( $scope.controls[x].allow )
                {
                    controls.push({
                        id          : $scope.controls[x].id,
                        description : $scope.controls[x].description,
                        color       : $scope.controls[x].color
                    });
                }
            }

            if($scope.invite.limitedAccess){
                $scope.daysOfWeek.forEach(e => {
                    if(e.selected)
                    $scope.invite.selectedDays.push(e.value);
                });
                
            }
            if(controls.length == 0){
                ngToast.danger({
                    content: 'Selecione ao menos um controle para convidar',
                    dismissOnTimeout: true,
                    timeout: 3000
                });
                return;
            }
            console.log($scope.invite.phone.length);

            if($scope.invite.phone.length < 10){
                ngToast.danger({
                    content: 'Informe o Telefone com o código DDD',
                    dismissOnTimeout: true,
                    timeout: 3000
                });
                return;
            }
            // TODO: parar de assumir que estamos no brasil e permitira selecao do codigo de area dos paises
            $scope.invite.areaCode="+55";

            invite = {
                controls        : JSON.stringify( controls ),
                client_id       : $scope.invite.block.client_id,
                client_unit_id  : $scope.invite.client_unit_id,
                name            : $scope.invite.name,
                phone           : $scope.invite.areaCode+$scope.invite.phone,
                email           : $scope.invite.email,
                limited_access  : $scope.invite.limitedAccess,
                selected_days   : $scope.invite.selectedDays,
                start_at        : $scope.start_at==""?"": moment($scope.start_at).format("HH:mm"),
                end_at          : $scope.end_at==""?"": moment($scope.end_at).format("HH:mm"),
                start_at2       : $scope.start_at2==""?"": moment($scope.start_at2).format("HH:mm"),
                end_at2         : $scope.end_at2==""?"": moment($scope.end_at2).format("HH:mm"),
                start_at3       : $scope.start_at3==""?"": moment($scope.start_at3).format("HH:mm"),
                end_at3         : $scope.end_at3==""?"": moment($scope.end_at3).format("HH:mm"),
            }

            console.log(invite);
            $invite.save(invite).$promise.then(function(data){
                switch (data.msg) {
                  case "user_not_found_invited_created":
                    $scope.toast($scope.invite.name +'ainda nao se cadastrou na Noknox Doorman mas nao se preocupe, assim que ele(a) se cadastrar com o telefone informado as chaves já estarão disponiveis ', false);
                    break;
                  case "user_not_found_invited_created_email_sent":
                    $scope.toast($scope.invite.name +'ainda nao se cadastrou na Noknox Doorman mas nao se preocupe, um email foi enviado para '+$scope.invite.email+' e assim que ele(a) se cadastrar com o telefone informado as chaves já estarão disponiveis ', false);
                    break;
                  default:
                    $scope.toast('Convite enviado', false);
                };

                $uibModalInstance.close(data);
            }, function(error) {
                $scope.toast('Erro no envio do convite', true);
            });
        }

        $scope.toast = function(msg, error)
        {
            if(error) {
                ngToast.danger({
                    content: msg,
                    dismissOnTimeout: true,
                    timeout: 3000
                });
            } else {
                ngToast.success({
                    content: msg,
                    dismissOnTimeout: true,
                    timeout: 3000
                });
            }
        }

        $scope.cancel = function()
        {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.cocStandardSchedule= function(){
            $scope.daysOfWeek = [   {value:'sun',label:'Dom',selected:false},
                                {value:'mon',label:'Seg',selected:true},
                                {value:'wed',label:'Qua',selected:true},
                                {value:'tue',label:'Ter',selected:true},
                                {value:'thu',label:'Qui',selected:true},
                                {value:'fri',label:'Sex',selected:true},
                                {value:'sat',label:'Sab',selected:false},
                                ];

                                $scope.start_at="2022-03-15T10:00:02.291Z";
                                $scope.end_at= "2022-03-15T11:00:02.298Z";
                                $scope.start_at2="2022-03-15T15:00:02.300Z";
                                $scope.end_at2="2022-03-15T17:00:02.303Z";
                                $scope.start_at3="2022-03-15T20:30:02.306Z";
                                $scope.end_at3="2022-03-15T22:00:02.310Z";

            
        }
}]);

angular.module('permission')
.controller('confirm_manager', ['$scope', '$http', '$auth', '$state', '$localStorage', '$uibModalInstance', '$compile', '$uibModal', 'ngToast', 'manager',function($scope, $http, $auth, $state, $localStorage, $uibModalInstance, $compile, $uibModal, ngToast, manager) {

    $scope.manager = manager;
    $scope.title        = 'Confirmação de síndico para o condomínio ' + $scope.manager.client.name;
    $scope.cancel_text  = 'Cancelar';
    $scope.success_text = 'Concluído';
    $scope.email        = $scope.manager.email;

    $scope.confirm = function(code)
    {
        var data = {
            email: $scope.email,
            code: code
        };

        $http.post(api + 'client_manager/confirm_code', data).then(function(response){
            ngToast.success({
                content: 'Parabéns, controle de síndico adicionado com sucesso',
                dismissOnTimeout: true,
                timeout: 3000
            });
            $uibModalInstance.close(data);
        }, function(error){
            ngToast.danger({
                content: 'Codigo inválido',
                dismissOnTimeout: true,
                timeout: 3000
            });
        });
    }

    $scope.cancel = function()
    {
        $uibModalInstance.dismiss('cancel');
    };

}]);

angular.module('permission').
factory("$permission", [ '$resource','$q','$http', function($resource,$q,$http) {
    var resource = $resource(api + 'user/permissions/:id', null, {
        'query': {
            method: 'GET',
            isArray: true
        },
        'update': {
            method: 'PUT'
        }
    });

    return resource;
}]);

angular.module('permission').
factory("$user_device", [ '$resource','$q','$http', function($resource,$q,$http) {
    var resource = $resource(api + 'user/device/:id', null, {
        'query': {
            method: 'GET',
            isArray: true
        },
        'update': {
            method: 'PUT'
        }
    });

    return resource;
}]);
