
angular.module('home', ['ui.router','confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap', 'toggle-switch','daterangepicker','rzSlider']);


angular.module('home').config(['$stateProvider','$urlRouterProvider', function($stateProvider,$urlRouterProvider) {

    $stateProvider

    .state('home', {
        url: "/",
        parent: 'app.user',
        views: {
            'content':{
                templateUrl: server+"/views/m/home",
                controller: 'home'
            }
        }
    })

    .state('about', {
        url: "/about",
        parent: 'app.public',
        views: {
            'content':{
                templateUrl: server+"/views/public/about",
                controller: 'about'
            }
        }
    })

    .state('policy', {
        url: "/policy",
        parent: 'app.public',
        views: {
            'content':{
                templateUrl: server+"/views/public/policy",
                controller: 'policy'
            }
        }
    })

    .state('politica', {
        url: "/politica",
        parent: 'app.public',
        views: {
            'content':{
                templateUrl: server+"/views/public/policy",
                controller: 'policy'
            }
        }
    })

    .state('terms', {
        url: "/terms",
        parent: 'app.public',
        views: {
            'content':{
                templateUrl: server+"/views/public/terms",
                controller: 'terms'
            }
        }
    })


}]);

angular.module('home').
filter('startFrom', function() {
    return function(input, start) {
        start = +start; //parse to int
        return input.slice(start);
    }
});
angular.module('home').
controller('home', ['$scope', '$http', '$controls','$user', '$users', '$task','$uibModal', '$client_manager', '$invite','$client', 'NgTableParams', 'ngToast',
function($scope, $http,  $controls, $user, $users, $task, $uibModal, $client_manager, $invite, $client, NgTableParams, ngToast) {
    //Controllers

    $scope.loadControls = function(){
        $controls.query().$promise.then(function(data){
            $scope.controls = data;
        });
    }

    $scope.loadControls();

    $scope.currentPage = 0;
    $scope.pageSize = 10;
    $scope.data = [];
    $scope.panel = null;

    var d = new Date();
    d.setHours( 14 );
    d.setMinutes( 0 );
    $scope.startHour =  d;

    $scope.endHour = moment().startOf('day').toDate();
    $scope.hstep = 1;
    $scope.mstep = 15;

    $scope.filterHistory = {
        range: {
            date: {startDate: moment().subtract(6, 'days'), endDate: moment()},
            options: {
                pickerClasses: 'custom-display', //angular-daterangepicker extra
                buttonClasses: 'btn',
                applyButtonClasses: 'btn-primary',
                cancelButtonClasses: 'btn-danger',
                locale: {
                    applyLabel: "OK",
                    cancelLabel: 'Cancelar',
                    customRangeLabel: 'Outro período',
                    separator: ' - ',
                    format: "DD/MM/YYYY",
                },
                ranges: {
                    'Últimos 7 dias': [moment().subtract(6, 'days'), moment()],
                    'Últimos 30 dias': [moment().subtract(29, 'days'), moment()]
                },
                eventHandlers: {
                    'apply.daterangepicker': function(event, picker) { console.log('applied'); }
                }
            }
        }
    }

    $scope.loadingHistory = false;

    $scope.filterAdmin = function(data) {
        if($scope.clientId){
            var aReturn = [];
            _.each(data, function(row){
                if(row.config.client_id == $scope.clientId) {
                    aReturn.push(row);
                }
            });
            return aReturn;

        } else {
            return data;
        }
    }

    $scope.history = function(key){
        $scope.panel = 'history';

        _.each($scope.controls, function(value,key){
            value.selected = false;
        });
        key.selected = true;
        $scope.selectedKey = key.client_access_id;
        $scope.getHistory();
    }

    $scope.minDistance = 100;
    $scope.maxDistance = 1000;

    $scope.sliderOptions = {
        floor: 100,
        ceil: 1000,
      }

    $scope.config = function(key){
        
        
        $scope.panel = 'config';

        $scope.clearSelectedKey();
        key.selected = true;
        key.config.notify_when_open  = parseInt(key.notify_when_open);
        $scope.selectedKey = angular.copy(key);
        
        if($scope.selectedKey.config.max_occupants>=1){
            $scope.selectedKey.config.maxOccupantsSet=true;
        }else{
            $scope.selectedKey.config.maxOccupantsSet=false;
        }
        
        $scope.showGpsOptions = $scope.selectedKey.config.gps_mandatory;
        //$scope.selectedKey.config.notify_when_open = $scope.selectedKey.notify_when_open;
        $scope.startHour = $scope.selectedKey.config.keep_door_state_start==""?"": moment($scope.selectedKey.config.keep_door_state_start).format("hh:mm");
        $scope.endHour = $scope.selectedKey.config.keep_door_state_end==""?"": moment($scope.selectedKey.config.keep_door_state_end).format("hh:mm");

        if($scope.selectedKey.config.last_used_by_user_id){
            $user.get({id : $scope.selectedKey.config.last_used_by_user_id}).$promise.then(function(response){
                $scope.selectedKey.config.current_user = response;
                
            });
            moment.locale('pt-br');
            $scope.selectedKey.config.cart_removed_at = moment($scope.selectedKey.config.cart_removed_at, "YYYY-MM-DD hh:mm:ss").fromNow();
            $scope.selectedKey.config.is_late = moment($scope.selectedKey.config.cart_removed_at, "YYYY-MM-DD hh:mm:ss").fromNow();
        }

        console.log($scope.selectedKey);

        

    }

    $scope.switchChange = function(){
        if($scope.selectedKey.config.switch)
        $scope.selectedKey.config.keep_door_state = false;
    }

    $scope.maxOccupantsSetChange=function(){
        
        if($scope.selectedKey.config.maxOccupantsSet){
            $scope.selectedKey.config.max_occupants = 1;
        }else{
            $scope.selectedKey.config.max_occupants = 0;
        }
    }
    $scope.maxOccupantsChange=function(value){
           
            $scope.selectedKey.config.max_occupants = value;
            if(value==0)
            $scope.selectedKey.config.maxOccupantsSet=false;
    }

    $scope.keepDoorStateChange = function(){
        if($scope.selectedKey.config.keep_door_state)
        $scope.selectedKey.config.switch = false;
    }

    $scope.gpsChange = function(prev)
    {
        if($scope.selectedKey.config.gps_mandatory == true){
            $scope.showGpsOptions = true;
        } else {

            // mostrar alerta
            $scope.showGpsOptions = false;

            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'confirm.modal.html',
                size: 'sm',
                scope: $scope,
                backdrop: 'static',
                controller: ['$state', '$scope', '$uibModalInstance', '$accounts_plan', function($state, $scope, $uibModalInstance, $accounts_plan) {

                    $scope.header = "ATENÇÃO";
                    $scope.title = 'O aplicativo não será capaz de detectar de onde o usuário esta solicitando a abertura da porta e essa informação não será coletada. Deseja realmente continuar?';
                    $scope.cancel_text = 'Não';
                    $scope.success_text = 'Sim';

                    $scope.confirm = function() {
                        $uibModalInstance.dismiss('cancel');
                        $scope.$parent.selectedKey.config.gps_mandatory = false;
                    }

                    $scope.cancel = function() {
                        $scope.$parent.selectedKey.config.gps_mandatory = true;
                        $scope.$parent.showGpsOptions = true;
                        $uibModalInstance.dismiss('cancel');
                    };

                }]
            });
        }
    }

    $scope.clearSelectedKey = function(){
        _.each($scope.controls, function(value,key){
            value.selected = false;
        });
    }

    $scope.changeStartHour = function (newValue) {
        $scope.startHour = newValue;
    };

    $scope.changeEndHour = function (newValue) {
        $scope.endHour = newValue;
    };



    $scope.showInvite= function(user){
        console.log("chamou");
        if( !user.user_id )
            return;
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'showUser.modal.html',
            size: 'sm',
            scope: $scope,
            backdrop: 'static',
            controller: ['$state', '$scope', '$uibModalInstance', '$user', '$user_device',
            function($state, $scope, $uibModalInstance, $user, $user_device) {

                $scope.title = 'Usuário';
                $user.get({id : user.user_id, clientId : $scope.clientId}).$promise.then(function(response){
                    $scope.user  = response;
                    $scope.notification = {
                        subject : '',
                        text    : '',
                        to      : 'user',
                        user_id : $scope.user.id
                    }

                }, function(error){
                    console.log(error);
                });

                $scope.notify = function()
                {
                    $client.notify($scope.$parent.clientId, $scope.notification).then(function(response){
                        ngToast.success({
                            content: 'Notificação enviada',
                            dismissOnTimeout: true,
                            timeout: 3000
                        });
                    }, function(error){
                        ngToast.danger({
                            content: 'Erro!',
                            dismissOnTimeout: true,
                            timeout: 3000
                        });
                    });
                };

                $scope.removeDevice = function(device) {
                    $user_device.delete({id: device.hash}).$promise.then(function(response){
                        $uibModalInstance.close();
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.showUser = function(user){
        console.log("chamou");
       
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'showUser.modal.html',
            size: 'sm',
            scope: $scope,
            backdrop: 'static',
            controller: ['$state', '$scope', '$uibModalInstance', '$user', '$user_device',
            function($state, $scope, $uibModalInstance, $user, $user_device) {

                $scope.title = 'Usuário';
                $user.get({id : user.id, clientId : $scope.clientId}).$promise.then(function(response){
                    $scope.user  = response;
                    $scope.notification = {
                        subject : '',
                        text    : '',
                        to      : 'user',
                        user_id : user.id
                    }

                }, function(error){
                    console.log(error);
                });

                $scope.notify = function()
                {
                    $client.notify($scope.$parent.clientId, $scope.notification).then(function(response){
                        ngToast.success({
                            content: 'Notificação enviada',
                            dismissOnTimeout: true,
                            timeout: 3000
                        });
                    }, function(error){
                        ngToast.danger({
                            content: 'Erro!',
                            dismissOnTimeout: true,
                            timeout: 3000
                        });
                    });
                };

                $scope.removeDevice = function(device) {
                    $user_device.delete({id: device.hash}).$promise.then(function(response){
                        $uibModalInstance.close();
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.saveConfig = function(){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            backdrop: 'static',
            controller: ['$state', '$scope', '$uibModalInstance', '$accounts_plan', function($state, $scope, $uibModalInstance, $accounts_plan) {

                $scope.header = "Confirmação";
                $scope.title = 'Salvar as configurações da porta?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                $scope.confirm = function() {
                    var data = {
                        "nickname"             : $scope.selectedKey.nickname,
                        "allow_remote_trigger" : $scope.selectedKey.config.allow_remote_trigger,
                        "notify_when_open"     : $scope.selectedKey.config.notify_when_open,
                        "allow_share"          : $scope.selectedKey.config.allow_share,
                        "gps_mandatory"        : $scope.selectedKey.config.gps_mandatory,
                        "min_distance"         : $scope.selectedKey.config.min_distance,
                        "switch"               : $scope.selectedKey.config.switch,
                        "locked"               : $scope.selectedKey.config.locked,
                        "mandatory_tag_reading": $scope.selectedKey.config.mandatory_tag_reading,
                        "max_occupants"        : $scope.selectedKey.config.max_occupants,
                        "max_time_before_alarm": $scope.selectedKey.config.max_time_before_alarm,
                        "keep_door_state"      : $scope.selectedKey.config.keep_door_state,
                        "keep_door_state_start": moment($scope.startHour).format('HH:mm'),
                        "keep_door_state_end"  : moment($scope.endHour).format('HH:mm')
                    }
                    $http.put(api+'user/device/'+$scope.selectedKey.hash, data).then((response) => {
                        $uibModalInstance.dismiss('cancel');
                        $scope.selectedKey = undefined;
                        $scope.$parent.showConfig = false;
                        $scope.$parent.loadControls();

                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.getHistory = function(){
        $scope.loadingHistory = true;
        $scope.events = undefined;

        var filter = {
            'filter[client_access_id]': $scope.selectedKey,
            'start_at': $scope.filterHistory.range.date.startDate.toDate(),
            'end_at': $scope.filterHistory.range.date.endDate.toDate(),
            'name': $scope.filterHistory.name,
            'count' : 1000
        }

        $task.get(filter).$promise.then(function(data) {
            $scope.loadingHistory = false;
            $scope.events = data.data;
            $scope.numberOfPages();
        });
    }

    $scope.numberOfPages=function(){
       return Math.ceil($scope.events.length/$scope.pageSize);
    }

    $scope.openKey = function(key){

        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$accounts_plan', function($state, $scope, $uibModalInstance, $accounts_plan) {

                $scope.title = 'Abrir porta?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                var role = $scope.$parent.role;

                $scope.confirm = function() {

                    $uibModalInstance.dismiss('cancel');

                    key.showLoader = true;
                    key.showOpen = false;
                    key.openSuccess = false;

                    // abrir
                    $task.save({
                        "user_device_id": key.hash,
                        "action": "open",
                        "distance":null,
                        "latitude": null,
                        "longitude": null
                    }).$promise.then(function(response){
                        key.showLoader = false;
                        key.openSuccess = true;
                        setTimeout(() => {
                            key.openSuccess = false;
                            key.showOpen = true;
                        }, 500);
                    })

                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.showAdmPanel = function(clientId){
        $scope.panel = 'users';
        $scope.clientId = clientId;
        $scope.tableInvites = new NgTableParams({
            page: 1,
            count: 20,
            client_id: clientId
        }, {
            counts: [],
            filterDelay: 10,
            getData: function(params) {
                var result = $invite.query(params.url()).$promise.then(function(data) {
                    // console.log(data);
                    params.total(data.total);
                    return data.data;
                }, function(error){

                });

                return result;
            }
        });


        $scope.usersTable = new NgTableParams({
            page: 1,
            count: 20,
            client_id: clientId,
            used: false
        }, {
            counts: [],
            filterDelay: 10,
            getData: function(params) {

                var result = $users.getUsersbyClientId(clientId, params).then(function(data) {
                    
                    return data;
                }, function(error){

                });

                return result;
            }
        });

    }

    $client_manager.getAll().then(function(response){
        $scope.managings = response;
        
    });

    $scope.resendInvite = function(id)
    {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', function($state, $scope, $uibModalInstance) {

                $scope.title = 'Deseja reenviar este convite?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                $scope.confirm = function() {
                    $invite.resend(id).then(function(response){
                        $scope.toast('Convite enviado!', false);
                    }, function(error) {
                        $scope.toast('Erro ao enviar convite!', true);
                    });

                    $uibModalInstance.dismiss('cancel');
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.deleteInvite = function(id)
    {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', function($state, $scope, $uibModalInstance) {

                $scope.title = 'Deseja remover esse convite?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                $scope.confirm = function() {
                    $invite.delete({id:id}).$promise.then(function(response){
                        $scope.tableInvites.reload();
                        $scope.toast('Convite apagado!', false);
                    }, function(error) {
                        $scope.toast('Erro ao apagar convite!', true);
                    });

                    $uibModalInstance.dismiss('cancel');
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.activateInvite = function(id)
    {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', function($state, $scope, $uibModalInstance) {

                $scope.title = 'Forçar a ativação desse convite?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                $scope.confirm = function() {
                    $invite.activate(id).then(function(response){
                        $scope.toast('Controles ativados!', false);
                        $scope.tableInvites.reload();
                    }, function(error) {
                        $scope.toast('Usuário ainda não se cadastrou na plataforma.', true);
                    });

                    $uibModalInstance.dismiss('cancel');
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.toast = function(msg, error)
    {
        if(error) {
            ngToast.danger({
                content: msg,
                dismissOnTimeout: true,
                timeout: 3000
            });
        } else {
            ngToast.success({
                content: msg,
                dismissOnTimeout: true,
                timeout: 3000
            });
        }
    }

    $scope.addNewInvite = function()
    {
        var modalInstance = $uibModal.open({
            animation      : true,
            ariaLabelledBy : 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl    : 'newInvite.modal.html',
            controller     : 'invite.create',
            size           : 'lg',
            resolve        : {
                client_id : $scope.clientId
            }
        });

        modalInstance.result.then(function() {
            $scope.tableInvites.reload();
        });
    }

    $scope.notify = function(user = null)
    {
        var modalInstance = $uibModal.open({
            animation      : true,
            ariaLabelledBy : 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl    : 'notify.modal.html',
            controller     : 'client.notify',
            size           : 'md',
            resolve        : {
                client_id       : $scope.clientId,
                user            : user
            }
        });

        modalInstance.result.then(function() {

        });
    }

}]);


angular.module('home').
controller('about', ['$scope', '$http', '$auth','$state', '$localStorage','$timeout', function($scope, $http, $auth, $state, $localStorage, $timeout) {

}]);

angular.module('home').
controller('policy', ['$scope', '$http', '$auth','$state', '$localStorage','$timeout', function($scope, $http, $auth, $state, $localStorage, $timeout) {

}]);

angular.module('home').
controller('terms', ['$scope', '$http', '$auth','$state', '$localStorage','$timeout', function($scope, $http, $auth, $state, $localStorage, $timeout) {

}]);

angular.module('home').
factory("$controls", [ '$resource', function($resource) {
    var resource = $resource(api + 'user/device/:id', null, {
        'query': {
            method: 'GET',
            isArray: true
        },
        'update': {
            method: 'PUT'
        }
    });
    return resource;

}]);


angular.module('home').filter('groupBy', ['$parse', function ($parse) {
    return function (list, group_by) {

        var filtered = [];
        var prev_item = null;
        var group_changed = false;
        // this is a new field which is added to each item where we append "_CHANGED"
        // to indicate a field change in the list
        //was var new_field = group_by + '_CHANGED'; - JB 12/17/2013
        var new_field = 'group_by_CHANGED';

        // loop through each item in the list
        angular.forEach(list, function (item) {

            group_changed = false;

            // if not the first item
            if (prev_item !== null) {

                // check if any of the group by field changed

                //force group_by into Array
                group_by = angular.isArray(group_by) ? group_by : [group_by];

                //check each group by parameter
                for (var i = 0, len = group_by.length; i < len; i++) {
                    if ($parse(group_by[i])(prev_item) !== $parse(group_by[i])(item)) {
                        group_changed = true;
                    }
                }


            }// otherwise we have the first item in the list which is new
            else {
                group_changed = true;
            }

            // if the group changed, then add a new field to the item
            // to indicate this
            if (group_changed) {
                item[new_field] = true;
            } else {
                item[new_field] = false;
            }

            filtered.push(item);
            prev_item = item;

        });

        return filtered;
    };
}]);
