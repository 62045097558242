angular.module('welcome', ['ui.router','confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap', 'toggle-switch']);

angular.module('welcome').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

    $stateProvider

    .state('welcome', {
        url         : "/welcome",
        parent: 'app.user',
        views: {
            'content':{
                templateUrl : server+"/views/m/welcome",
                controller  : 'ctrl',
            }
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Bem-vindo'
        }
    });
}]);

angular.module('welcome').
controller('ctrl', ['$rootScope','$scope', '$http', '$auth','$state', '$localStorage', 'WizardHandler',
function($rootScope, $scope, $http, $auth, $state, $localStorage, WizardHandler) {

}]);
