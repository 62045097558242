angular.module('share', ['ui.router','confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap']);

angular.module('share').config(['$stateProvider', '$urlRouterProvider','ngToastProvider', function($stateProvider, $urlRouterProvider, ngToast) {

    ngToast.configure({
      verticalPosition: 'bottom',
      horizontalPosition: 'center',
      maxNumber: 3,
      additionalClasses: 'toaster'
    });

    $stateProvider
        .state('share', {
            url         : "/share/:id",
            parent      : 'app.public',
            views: {
                'content':{
                    templateUrl : server+"/views/public/share",
                    controller  : 'share.view',
                }
            }
        });
}]);

/**
 * List Controller
 */
angular.module('request')
.filter('getDate', function(){
    return function(input){
        if( input ){
            return new moment(input).format('DD/MM/YY HH:mm');
        }
    }
})
.filter('maskPhone', function(){
    return function(phone){
        if( phone ){
            return '(**) *****-' + phone.substring(9, phone.length);
        }
    }
})
.controller('share.view', ['$scope', '$localStorage', '$http', '$stateParams', '$auth', '$q', '$share', 'ngToast', '$uibModal', '$location',
function($scope, $localStorage, $http, $stateParams, $auth, $q, $share, ngToast, $uibModal, $location) {

    $('meta[name=viewport]').attr('content', '<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0">');


    $scope.today            = new moment();
    $scope.code             = '';
    $scope.geolocation      = {};
    $scope.timeOut          = false;
    $scope.currentStep      = 1;
    $scope.loading          = false;
    if( $localStorage.ses && $localStorage.ses.id == $stateParams.id ){
            $scope.session = $localStorage.ses;
    } else {
        $scope.session = {
            id : $stateParams.id,
            phone_number : '',
            authorization_token : ''
        }
    }

    $share.get({
        id: $stateParams.id
    }).$promise.then(function(data) {
        $scope.share = data;
    }, function(error) {
        $scope.error = true;
        if (error.status == 404) {
            $scope.msgError = 'Compartilhamento não encontrado';
            ngToast.danger({
                content: $scope.msgError,
                dismissOnTimeout: true,
                timeout: 3000
            });
        }
        if (error.status == 500) {
            $scope.msgError = 'Compartilhamento não válido';
            ngToast.danger({
                content: $scope.msgError,
                dismissOnTimeout: true,
                timeout: 3000
            });
        }
    });

    $scope.sendCode = function()
    {
        if($scope.session.phone_number == undefined || $scope.session.phone_number == '' || $scope.session.phone_number.length < 10)
        {
            ngToast.danger({
                content: 'Por favor informe o número de telefone com DDD',
                dismissOnTimeout: true,
                timeout: 3000
            });

            return false;
        }

        $localStorage.ses = $scope.session;
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'geolocation.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$uibModal',
            function($state, $scope, $uibModalInstance, $uibModal) {

                $scope.text = 'Precisamos da sua localização para continuar. Na tela a seguir clique em Permitir.';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Ok';

                $scope.confirm = function() {

                    var geolocation = {};
                    $scope.waiting = true;

                    function watchLocation(successCallback, errorCallback) {
                        successCallback = successCallback || function(){};
                        errorCallback = errorCallback || function(){};
                        // Try HTML5-spec geolocation.
                        var geolocation = navigator.geolocation;
                        if (geolocation) {
                            // We have a real geolocation service.
                            try {
                                function handleSuccess(position) {
                                    successCallback(position.coords);
                                }
                                geolocation.watchPosition(handleSuccess, errorCallback, {
                                    enableHighAccuracy: true,
                                    maximumAge: 10000 // 10 sec.
                                });
                            } catch (err) {
                                errorCallback();
                            }
                        } else {
                            errorCallback();
                        }
                    }

                    watchLocation(function(coords) {
                        console.log(coords);
                        $scope.$apply(function(){
                            geolocation.latitude = coords.latitude;
                            geolocation.longitude = coords.longitude;
                            $uibModalInstance.close(geolocation);
                        });
                    }, function(error) {
                        console.log(error.message);
                        if(error.message = "User denied Geolocation"){
                            $scope.$parent.error = true;
                            $scope.$parent.msgError = "Você precisa permitir o uso da localização para continuar.";
                            return;
                        }
                        $scope.$parent.geoError = true;
                        $uibModalInstance.dismiss('cancel');
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });

        modalInstance.result.then(function(geolocation)
        {

            if( !geolocation.latitude || !geolocation.longitude ){
                $scope.$parent.geoError = true;
                return;
            }

            $scope.geolocation.latitude     = geolocation.latitude;
            $scope.geolocation.longitude    = geolocation.longitude;
            console.log($scope.share.user_device.device.latitude);
            console.log($scope.share.user_device.device.longitude);

            $scope.geolocation.distance = $scope.getDistanceFromLatLonInKm($scope.geolocation.latitude,
                                                                                $scope.geolocation.longitude,
                                                                                $scope.share.user_device.device.latitude,
                                                                                $scope.share.user_device.device.longitude);
            console.log($scope.geolocation.distance);
            if( $scope.geolocation.distance > 0.5 ){
                $scope.$parent.error = true;
                $scope.$parent.msgError = "Você está muito longe do portão!";
                return;
            }

            $scope.send();
        });

    }

    $scope.send = function()
    {
        $scope.timeOut = false;
        $share.sendCode($scope.share.hash, {    phone_number : $scope.session.phone_number,
                                                authorization_token : $scope.session.authorization_token
                                            }).then(function(response){
            if( response.code ){
                $scope.code = parseInt( response.code );
                angular.element('#card_'+$scope.currentStep).addClass("hidden");
                $scope.loading = true;
                $scope.confirmCode();
            } else {
                $scope.nextStep();

                setTimeout(function () {
                    $scope.$apply(function(){
                        $scope.timeOut = true;
                    })
                }, 50000);
            }
        }, function(error) {
            if (error.status == 404) {
                ngToast.danger({
                    content: 'Este número não esta na lista de convidados',
                    dismissOnTimeout: true,
                    timeout: 3000
                });
            }
        });
    }

    $scope.nextStep = function(forcePage = null)
    {
        var current = $scope.currentStep;
        setTimeout(function () {
            angular.element('#card_'+current).addClass("hidden");
        }, 250);
        if( forcePage ){
            $scope.currentStep = forcePage;
        } else {
            $scope.currentStep = parseInt($scope.currentStep) + 1;
        }
    }

    /**
    * Distance between points
    */
    $scope.getDistanceFromLatLonInKm = function(lat1, lon1, lat2, lon2)
    {

        function deg2rad(deg) {
            return deg * (Math.PI / 180)
        }

        var R = 6371; // Radius of the earth in km
        var dLat = deg2rad(lat2 - lat1); // deg2rad below
        var dLon = deg2rad(lon2 - lon1);
        var a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c; // Distance in km
        return d;
    }

    $scope.confirmCode = function()
    {
        $scope.loading = true;
        var params = {
            code : $scope.code,
            latitude : $scope.geolocation.latitude,
            longitude : $scope.geolocation.longitude,
            distance : $scope.geolocation.distance,
            phone_number : $scope.session.phone_number
        }
        $share.use($scope.share.hash, params).then(function(response){
            $scope.session.authorization_token = response.authorization_token;
            $localStorage.ses = $scope.session;
            $scope.nextStep(3);
        }, function(error) {
            $scope.loading = false;
            if (error.status == 404) {
                ngToast.danger({
                    content: 'Não encontrado',
                    dismissOnTimeout: true,
                    timeout: 3000
                });
            }
            if (error.data.message == 'code_invalid') {
                ngToast.danger({
                    content: 'Codigo Inválido',
                    dismissOnTimeout: true,
                    timeout: 3000
                });
            }
            if (error.data.message == 'share_used') {
                ngToast.danger({
                    content: 'Compartilhamento usado',
                    dismissOnTimeout: true,
                    timeout: 3000
                });
            }
        });
    }

    $scope.goSite = function()
    {
        window.location.href = 'http://'+$location.host();
    }
}]);


/**
 * Factory
 */
angular.module('share').
factory("$share", [ '$resource', '$q', '$http', function($resource, $q, $http) {
    var resource = $resource(api + 'share/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        }
    });

    resource.sendCode = function(id, params){
        var deferred = $q.defer();
        $http.post(api+'share/'+id+'/sendCode', params).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.use = function(id, params){
        var deferred = $q.defer();
        $http.post(api+'share/'+id+'/use', params).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    return resource;
}]);
