angular.module('config', ['ui.router']);

angular.module('config').config(['$stateProvider','$urlRouterProvider', function($stateProvider,$urlRouterProvider) {
    $stateProvider
    .state('config', {
        url: "/config",
        templateUrl: server+"/views/m/config",
        controller: 'config',
        ncyBreadcrumb: {
            parent: 'home',
            label: 'configurações'
        }
    })
}]);


angular.module('config').
controller('config', ['$rootScope','$scope', '$http', '$auth','$state', '$localStorage',
function($rootScope, $scope, $http, $auth, $state, $localStorage) {

}]);
