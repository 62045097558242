angular.module('device_model', ['ui.router', 'confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap', 'toggle-switch', 'ngFileUpload']);

angular.module('device_model').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

    $stateProvider

        .state('device_model', {
            url         : "/device_model",
            parent      : 'app.admin',
            views: {
                'content':{
                    templateUrl : server+"/views/m/device_model",
                    controller  : 'device_model.list',
                }
            },
            ncyBreadcrumb: {
                parent: 'home',
                label: 'Modelos'
            }
        })

        .state('device_model_create', {
            url         : "/device_model/create",
            parent      : 'app.admin',
            views: {
                'content':{
                    templateUrl : server+"/views/m/device_model/create",
                    controller  : 'device_model.create',
                }
            },
            ncyBreadcrumb: {
                parent: 'device_model',
                label: '{{device_model.display_name}}'
            }
        })

        .state('device_model_show', {
            url         : "/device_model/:id",
            parent      : 'app.admin',
            views: {
                'content':{
                    templateUrl : server+"/views/m/device_model/show",
                    controller  : 'device_model.show',
                }
            },
            ncyBreadcrumb: {
                parent: 'device_model',
                label: '{{device_model.display_name}}'
            }
        })

}]);

/**
 * List Controller
 */
angular.module('device_model').
controller('device_model.list', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$device_model', function($scope, $http, $auth, $q, NgTableParams, $device_model) {
    $scope.tableParams = new NgTableParams({
        page: 1,
        count: 10
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $device_model.query(params.url()).$promise.then(function(data) {
                params.total(data.total);
                return data.data;
            });
        }
    });
}]);

/**
 * Create Controller
 */
angular.module('device_model').
controller('device_model.create', ['$scope', '$state', '$device_model', function($scope, $state, $device_model) {
    $scope.device_model = {
        name : '',
        server : null
    }
    $scope.save = function() {
        $device_model.save($scope.device_model).$promise.then(function(data) {
            $state.go('device_model_show', {
                id: data.id
            });
        });
    }
}]);

/**
 * Show, Edit, Delete Controller
 */
angular.module('device_model').
controller('device_model.show', ['$state', '$scope', '$stateParams', '$uibModal', '$device_model', '$templateCache', '$http',
function($state, $scope, $stateParams, $uibModal, $device_model, $templateCache, $http) {

    $scope.editing = false;

    $scope.toggleEdit = function() {
        $scope.editing = !$scope.editing;
    }

    $device_model.get({
        id: $stateParams.id
    }).$promise.then(function(data) {
        $scope.device_model = data;
    }, function(error) {
        if (error.status == 404) {
            $state.go('notFound');
        }
    });

    $scope.confirmUpdate = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$device_model', function($scope, $uibModalInstance, $device_model) {

                $scope.title = 'Deseja atualizar?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Concluído';

                var device_model = $scope.$parent.device_model;

                $scope.confirm = function() {
                    $device_model.update({
                        id: device_model.hash
                    }, device_model).$promise.then(function(data) {
                        $uibModalInstance.dismiss('cancel');
                        $scope.$parent.toggleEdit();
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

    $scope.confirmDelete = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$device_model', function($state, $scope, $uibModalInstance, $device_model) {

                $scope.title = 'Deseja remover esse registro?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                var device_model = $scope.$parent.device_model;

                $scope.confirm = function() {
                    $device_model.delete({
                        id: device_model.hash
                    }).$promise.then(function(data) {
                        $uibModalInstance.dismiss('cancel');
                        $state.go('device_model');
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

    $scope.sendBinary = function() {

        var modalInstance = $uibModal.open({
            animation: true,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            windowTemplateUrl: 'windowCentered.modal.html',
            templateUrl: 'sendBinary.modal.html',
            controller: ['$uibModalInstance','Upload', '$timeout', '$stateParams', function($uibModalInstance, Upload, $timeout,$stateParams) {

                var $ctrl = this;

                $ctrl.image;

                $ctrl.progress = 0;

                $ctrl.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

                $ctrl.upload = function(file, version) {
                    console.log(file, version);
                    Upload.upload({
                        url: api + 'device_model/'+$stateParams.id+'/sendbinary',
                        data: {
                            version: version,
                            file: file
                        },
                    }).then(function(response) {
                        $timeout(function() {
                            $uibModalInstance.close(response.data);
                            window.location.reload();
                        }, 500);
                    }, function(response) {
                        if (response.status > 0) console.log(response.status + ': ' + response.data);
                    }, function(evt) {
                        $ctrl.progress = parseInt(100.0 * evt.loaded / evt.total);
                    });
                }
            }],
            controllerAs: '$ctrl'
        });

        modalInstance.result.then(function(result) {
            console.log(result);
        });
    }

    $scope.download = function(serial, version)
    {
        $http.get(api + 'device_model/' + serial + '/getBinary/' + version,{responseType: 'arraybuffer'}).then(function(response){
            var file = new Blob([response.data], {type: response.headers('formato')});
            var fileURL = URL.createObjectURL(file);
            // Create a link element on body
            var link = angular.element('<a href="' + fileURL + '" target="_blank"></a>');
            angular.element(document.body).append(link);
            link[0].click();
            link.remove();
        });
    }

}]);


/**
 * Factory
 */
angular.module('device_model').
factory("$device_model", ['$resource', function($resource) {
    var resource = $resource(api + 'device_model/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        }
    });

    return resource;
}]);

angular.module("confirm.modal.html", []).run(["$templateCache", function($templateCache) {
    $templateCache.put('confirm.modal.html',
        '<div class="modal-body">' +
        ' {{title}}' +
        '</div>' +
        '<div class="modal-footer">' +
        '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '    <button class="btn btn-outline btn-success" type="button" ng-click="confirm()">{{success_text}}</button>' +
        '</div>'
    );
}]);
