angular.module('client', ['ui.router','confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap']);

angular.module('client').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

    $stateProvider
    .state('client', {
        url         : "/client",
        parent: 'app.admin',
        views: {
            'content':{
                templateUrl : server+"/views/m/client",
                controller  : 'client.list',
            }
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Clientes'
        }
    })

    .state('client_create', {
        url         : "/client/create",
        parent: 'app.admin',
        views: {
            'content':{
                templateUrl : server+"/views/m/client/create",
                controller  : 'client.create',
            }
        },
        ncyBreadcrumb: {
            parent: 'client',
            label: 'Adicionar'
        }
    })

    .state('client_show', {
        url         : "/client/:id",
        parent: 'app.admin',
        views: {
            'content':{
                templateUrl : server+"/views/m/client/show",
                controller  : 'client.show',
            }
        },
        ncyBreadcrumb: {
            parent: 'client',
            label: '{{client.hash}}'
        }
    })

}]);

/**
 * List Controller
 */
angular.module('client').
controller('client.list', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$client', function($scope, $http, $auth, $q, NgTableParams, $client) {
    $scope.tableParams = new NgTableParams({
        page: 1,
        count: 10
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $client.query(params.url()).$promise.then(function(data) {
                params.total(data.total);
                return data.data;
            });
        }
    });
}]);

/**
 * Create Controller
 */
angular.module('client').
controller('client.create', ['$scope', '$state', '$client', '$timeout','$uibModal', '$http', 'ngToast', '$integrator',
function($scope, $state, $client, $timeout, $uibModal, $http, ngToast, $integrator) {

    $scope.client = {
        is_condominium        : true,
        has_apartment_building: true,
        apartment_buildings   : []
    }

    $scope.city = {};
    $scope.state = {};

    $integrator.query({count:1000}).$promise.then(function(response) {
        $scope.integrators = response.data;
    });

    $scope.$watch('client.postal_code', function(){
        if($scope.client.postal_code != undefined && $scope.client.postal_code.length == 8){
            $http.get(api+'address/getCEP/'+$scope.client.postal_code).then(function(response){
                $scope.client.address               = response.data.logradouro;
                $scope.client.neiborhood            = response.data.bairro;
                $scope.client.address_complement    = response.data.complemento;

                // Escolher UF
                $http.get(api+'address/getCities',{params: {ibge:response.data.ibge}}).then(function(response){
                    $scope.cities = [];
                    $scope.cities.push(response.data[0]);
                    $scope.client.city_id = response.data[0].id;
                });

                $http.get(api+'address/getStates',{params: {abbreviation:response.data.uf}}).then(function(response){
                    $scope.states = [];
                    $scope.states.push( response.data );
                    $scope.client.state_id = response.data.id;
                });
            });
        }
    });

    $scope.createUnities = function(block)
    {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'addblocks.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$client', function($scope, $uibModalInstance, $client) {

                $scope.title = 'Deseja atualizar?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Concluído';

                var client = $scope.$parent.client;

                $scope.floors = 1;
                $scope.apartments = 1;

                $scope.confirm = function() {
                    for (var f = 0; f < $scope.floors; f++) {
                        for (var i = 0; i < $scope.apartments; i++) {
                            block.unities.push({number:(i+1)+((f+1)*100)});
                        }
                    }
                    $uibModalInstance.dismiss('cancel');
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.addUnit = function(block, element)
    {
        block.unities.push({});
    }

    $scope.removeUnit = function(block, unit)
    {
        var index = block.unities.indexOf(unit);
        block.unities.splice(index, 1);
    }

    $scope.addApartmentBuilding = function()
    {
        $scope.client.apartment_buildings.push({
            unities:[]
        });
    }

    $scope.duplicateBlock = function(block)
    {
        block = angular.copy(block);
        block.name = '';
        $scope.client.apartment_buildings.push(block);
    }

    $scope.removeBlock = function(block)
    {
        var index = $scope.client.apartment_buildings.indexOf(block);
        $scope.client.apartment_buildings.splice(index, 1);
    }

    $scope.save = function()
    {
        var error = false;
        angular.forEach($scope.client.apartment_buildings, function(value, key) {
            if( value.unities.length == 0 ){
                ngToast.danger({
                    content: 'Erro! Bloco '+value.name+' sem unidades.',
                    dismissOnTimeout: true,
                    timeout: 3000
                });
                error = true;
            }
        });
        if( error )
            return;
        $client.save($scope.client).$promise.then(function(data) {
            $state.go('client_show', {
                id: data.id
            });
        });
    }

    $scope.checkDocument = function(data)
    {
        if( !data || data.length < 11 ){
            return;
        }
        $http.get(api+'client/checkDocument/'+data).then(function(response){
            $scope.documentError = false;
        }, function(error){
            $scope.documentError = true;
        });
    }
}]);

/**
 * Show, Edit, Delete Controller
 */
angular.module('client').
controller('client.show', ['$state', '$scope', '$stateParams', '$uibModal', '$client','$sce','NgTableParams', '$device', '$http', '$client_manager', '$client_access', 'ngToast', '$users', '$integrator',
function($state, $scope, $stateParams, $uibModal, $client,$sce, NgTableParams, $device, $http, $client_manager, $client_access, ngToast, $users, $integrator) {

    $scope.editing = false;

    $scope._ = _;
    $scope.selected_devices = [];
    $scope.selected_access = [];
    $scope.selected_manager = [];
    $scope.selected_dweller = [];
    $scope.notification = {
        subject : '',
        text    : '',
        to      : 'admin'
    }

    $scope.toggleEdit = function() {
        $scope.editing = !$scope.editing;
    }

    $client.get({
        id: $stateParams.id
    }).$promise.then(function(data) {
        data.qrcode = $sce.trustAsHtml(data.qrcode);
        $scope.client = data;
        console.log($scope.client);
        $scope.client.deleted_buildings = [];
        $scope.client.deleted_unities = [];
        $scope.originalDocument = $scope.client.document;
        $http.get(api+'address/getCities',{params: {id:$scope.client.city_id}}).then(function(response){
            $scope.cities = [];
            $scope.cities.push( response.data );
        });

        $http.get(api+'address/getStates',{params: {id:$scope.client.state_id}}).then(function(response){
            $scope.states = [];
            $scope.states.push( response.data );
        });
    }, function(error) {
        if (error.status == 404) {
            $state.go('notFound');
        }
    });

    $integrator.query({count:1000}).$promise.then(function(response) {
        $scope.integrators = response.data;
    });

    $scope.$watch('client.postal_code', function(){
        if($scope.editing && $scope.client.postal_code != undefined && $scope.client.postal_code.length == 8){
            $http.get(api+'address/getCEP/'+$scope.client.postal_code).then(function(response){
                $scope.client.address               = response.data.logradouro;
                $scope.client.neiborhood            = response.data.bairro;
                $scope.client.address_complement    = response.data.complemento;

                // Escolher UF
                $http.get(api+'address/getCities',{params: {ibge:response.data.ibge}}).then(function(response){
                    $scope.cities = [];
                    $scope.cities.push(response.data[0]);
                    $scope.client.city_id = response.data[0].id;
                });

                $http.get(api+'address/getStates',{params: {abbreviation:response.data.uf}}).then(function(response){
                    $scope.states = [];
                    $scope.states.push( response.data );
                    $scope.client.state_id = response.data.id;
                });
            });
        }
    });

    $scope.createUnities = function(block)
    {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'addblocks.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$client', function($scope, $uibModalInstance, $client) {

                $scope.title        = 'Deseja atualizar?';
                $scope.cancel_text  = 'Cancelar';
                $scope.success_text = 'Concluído';

                var client = $scope.$parent.client;

                $scope.floors = 1;
                $scope.apartments = 1;

                $scope.confirm = function() {
                    for (var f = 0; f < $scope.floors; f++) {
                        for (var i = 0; i < $scope.apartments; i++) {
                            block.unities.push({number:(i+1)+((f+1)*100)});
                        }
                    }
                    $scope.$parent.refreshClient();
                    $uibModalInstance.dismiss('cancel');
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.addManagers = function()
    {
        var modalInstance = $uibModal.open({
            animation      : true,
            ariaLabelledBy : 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl    : 'clientManagers.modal.html',
            controller     : 'client.managers',
            size           : 'md',
            resolve        : {
                client_id: function(){
                    return angular.copy($stateParams.id)
                }
            }
        });

        modalInstance.result.then(function(response) {
            $scope.managersParams.reload();
        });
    }

    $scope.addAccess = function()
    {
        var modalInstance = $uibModal.open({
            animation      : true,
            ariaLabelledBy : 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl    : 'clientAccess.modal.html',
            controller     : 'client.access',
            size           : 'md',
            resolve        : {
                client_id: function(){
                    return angular.copy($stateParams.id)
                }
            }
        });

        modalInstance.result.then(function(response) {
            $scope.accessParams.reload();
        });
    }

    $scope.editAccess = function(row)
    {
        console.log(row);
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'editAccess.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance','$client_access', function($scope, $uibModalInstance, $client_access) {
                $scope.access = row;
                console.log($scope.access);

                $scope.save = function()
                {
                    $client_access.update({
                        id: row.id
                    }, $scope.access).$promise.then(function(response){
                        $scope.$parent.refreshClient();
                        $uibModalInstance.close(response);
                    }, function(error){
                        console.log(error);
                    });
                }
            }]
        });
    }

    $scope.editUnitAccesses = function(unit){
        console.log("editUnitAccesses",unit);
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'editUnitAccess.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance','$client', function($scope, $uibModalInstance, $client) {
                $scope.unitAccesses = unit.access;
                $scope.availableAccesses=$scope.client.accesses;

                $scope.availableAccesses.forEach(availableAccess=> {
                    availableAccess.selected=false;
                });

                $scope.availableAccesses.forEach(availableAccess=> {
                    $scope.unitAccesses.forEach(unitAccess => {
                        if(availableAccess.id == unitAccess.id){
                            availableAccess.selected = true;
                            availableAccess.order = unitAccess.order;
                        }
                    });
                });

               

                $scope.cancel = function(){
                    $scope.unitAccesses = [];
                    $scope.availableAccesses = [];
                    $uibModalInstance.dismiss('cancel');
                }


                console.log($scope.unitAccesses);
                console.log($scope.availableAccesses);
                $scope.save = function()
                {   var selected = $scope.availableAccesses.filter(a => a.selected);
                    var selected_ids=selected.map(a => a.id);
                    console.log(selected_ids);


                    $client.updateUnitAcesess(unit.id, selected_ids).then(function(response){
                        $scope.$parent.refreshClient();
                   
                        $uibModalInstance.dismiss('cancel');
                    }, function(error){
                        console.log(error);
                        $uibModalInstance.dismiss('cancel');
                    });
                }
            }]
        });

    }

    $scope.refreshClient = function(){
        $client.get({
            id: $stateParams.id
        }).$promise.then(function(data) {
            $scope.client = data;
        });
    }

    $scope.editBlockAccesses = function(block){
        console.log("editBlockAccesses",block);
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'editUnitAccess.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance','$client', function($scope, $uibModalInstance, $client) {
                $scope.unitAccesses=[];
                $scope.availableAccesses=$scope.client.accesses;

                $scope.availableAccesses.forEach(availableAccess=> {
                    availableAccess.selected=false;
                });

                $scope.cancel = function(){
                    $scope.unitAccesses = [];
                    $scope.availableAccesses = [];
                    $uibModalInstance.dismiss('cancel');
                }


                $scope.save = function()
                {   var selected = $scope.availableAccesses.filter(a => a.selected);
                    var selected_ids=selected.map(a => a.id);
                    console.log(selected_ids);


                    $client.updateBlockAcesess(block.id, selected_ids).then(function(response){
                        $scope.$parent.refreshClient();
                        $uibModalInstance.dismiss('cancel');
                    }, function(error){
                        console.log(error);
                        $uibModalInstance.dismiss('cancel');
                    });
                }
            }]
        });

    }

    $scope.addUnit = function(block, element)
    {
        block.unities.push({});
    }


    $scope.removeUnit = function(block, unit)
    {
        var index = block.unities.indexOf(unit);
        block.unities.splice(index, 1);
        if(unit.id)
            $scope.client.deleted_unities.push(unit.id);
    }

    $scope.addApartmentBuilding = function()
    {
        $scope.client.buildings.push({
            unities:[]
        });
    }

    $scope.duplicateBlock = function(block)
    {
        block = angular.copy(block);
        block.name = '';
        $scope.client.buildings.push(block);
    }

    $scope.removeBlock = function(block)
    {
        var index = $scope.client.buildings.indexOf(block);
        $scope.client.buildings.splice(index, 1);

        if(block.id)
            $scope.client.deleted_buildings.push(block.id);
    }

    
    $scope.updateAvatar = function(files) {

            var formData = new FormData();
            var id = $scope.client.id;
            formData.append("file", files[0]);
            $client.updateAvatar(id,formData).then(function(response){
                $scope.refreshClient();
            });
            
        }
    

    $scope.confirmUpdate = function() {
        console.log("cliente")
        console.log($scope.client);
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$client', 'ngToast', function($scope, $uibModalInstance, $client, ngToast) {

                $scope.title = 'Deseja atualizar?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Concluído';
                console.log($scope.$parent.client);
                var client = $scope.$parent.client;

                $scope.confirm = function() {

                    var error = false;
                    angular.forEach(client.buildings, function(value, key) {
                        if( value.unities.length == 0 ){
                            ngToast.danger({
                                content: 'Erro! Bloco '+value.name+' sem unidades.',
                                dismissOnTimeout: true,
                                timeout: 3000
                            });
                            error = true;
                        }
                    });

                    if( error ){
                        return;
                    }

                    $client.update({
                        id: client.id
                    }, client).$promise.then(function(data) {
                        $scope.$parent.refreshClient();
                        $uibModalInstance.dismiss('cancel');
                        $scope.$parent.toggleEdit();
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

    $scope.confirmDelete = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$client', function($state, $scope, $uibModalInstance, $client) {

                $scope.title = 'Deseja remover esse registro?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                var client = $scope.$parent.client;

                $scope.confirm = function() {
                    $client.delete({id:client.id}).$promise.then(function(data){
                        $uibModalInstance.dismiss('cancel');
                        $state.go('client');
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

    $scope.select = function(row, obj){
        switch (obj) {
            case 'device':
                if(_.contains($scope.selected_devices, row.hash))
                {
                    var i = _.indexOf($scope.selected_devices, row.hash);
                    $scope.selected_devices.splice(i,1);
                } else {
                    $scope.selected_devices.push(row.hash);
                }
            break;
            case 'access':
                if(_.contains($scope.selected_access, row.id))
                {
                    var i = _.indexOf($scope.selected_access, row.id);
                    $scope.selected_access.splice(i,1);
                } else {
                    $scope.selected_access.push(row.id);
                }
            break;
            case 'manager':
                if(_.contains($scope.selected_manager, row.id))
                {
                    var i = _.indexOf($scope.selected_manager, row.id);
                    $scope.selected_manager.splice(i,1);
                } else {
                    $scope.selected_manager.push(row.id);
                }
            break;
            case 'dweller':
                if(_.contains($scope.selected_dweller, row.id))
                {
                    var i = _.indexOf($scope.selected_dweller, row.id);
                    $scope.selected_dweller.splice(i,1);
                } else {
                    $scope.selected_dweller.push(row.id);
                }
            break;
        }

    }

    $scope.accessParams = new NgTableParams({
        page: 1,
        count: 10,
        filter: {
            client_id: $stateParams.id
        }
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $client_access.query(params.url()).$promise.then(function(data) {
                params.total(data.total);
                return data.data;
            });
        }
    });

    $scope.deviceParams = new NgTableParams({
        page: 1,
        count: 1000,
        filter: {
            client_id: $stateParams.id
        }
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $device.query(params.url()).$promise.then(function(data) {
                params.total(data.total);
                var devices = data.data;
                devices.forEach(device => {
                    device.config = JSON.parse(device.config);
                    if(device.config.length>0){
                        var desc = "";
                        for (let i = 0; i < device.config.length; i++) {
                            const config = device.config[i];
                            desc = desc + config.description + ", ";
                        }
                        console.log(desc);
                        device.config = desc.slice(0, -2);
                    }else{
                        device.config="";
                    }
                   
                });
                return devices;
            });
        }
    });

    $scope.dashboard = function(){
        $device.dashboard().then(function(data){
            $scope.offline_devices = Math.round(data.offline*100/(data.offline+data.online));
            $scope.online_devices = Math.round(data.online*100/(data.offline+data.online));
            $scope.total_devices = data.offline+data.online;
            console.log(data);
            $scope.device_data = [{total:$scope.offline_devices, name: "Offline"},{total:$scope.online_devices,name: "Online"}];
        })
    }

    $scope.managersParams = new NgTableParams({
        page: 1,
        count: 10,
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $client_manager.getAllByClient($stateParams.id).then(function(response) {
                return response;
            });
        }
    });

    $scope.dwellersParams = new NgTableParams({
        page: 1,
        count: 10,
        filter: {
            client_id: $stateParams.id
        }
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $users.query(params.url()).$promise.then(function(response) {
                return response.data;
            });
        }
    });

    $scope.openTags = function( user_id ){
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'listTags.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$users', '_', '$q', function($scope, $uibModalInstance, $users, _, $q) {

                $scope.title = 'Lista de Tags';

                $users.getTags({user_id}).then(function(response){
                    $scope.tags = response.data;
                });
            }]
        });
    }

    $scope.addDevice = function(block)
    {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'adddevice.modal.html',
            size: 'lg',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$device', function($scope, $uibModalInstance, $device) {

                $scope.title        = 'Deseja atualizar?';
                $scope.cancel_text  = 'Cancelar';
                $scope.success_text = 'Salvar';

                $scope._ = _;

                $scope.selected_devices = [];

                $scope.select = function(row){
                    if(_.contains($scope.selected_devices, row.hash))
                    {
                        var i = _.indexOf($scope.selected_devices, row.hash);
                        $scope.selected_devices.splice(i,1);
                    } else {
                        $scope.selected_devices.push(row.hash);
                    }
                }

                $scope.devices = new NgTableParams({
                    page: 1,
                    count: 10,
                    filter: {
                        client_id: 'available'
                    }
                }, {
                    counts: [],
                    filterDelay: 0,
                    getData: function(params) {
                        return $device.query(params.url()).$promise.then(function(data) {
                            params.total(data.total);
                            return data.data;
                        });
                    }
                });

                $scope.confirm = function() {
                    $client.enableDevices($stateParams.id, $scope.selected_devices).then(function(){
                        $scope.$parent.deviceParams.reload();
                        $uibModalInstance.dismiss('cancel');
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.removeDevices = function()
    {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$client', function($state, $scope, $uibModalInstance, $client) {

                $scope.title = 'Deseja desvincular esses dispositivos?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                $scope.confirm = function() {
                    $client.removeDevices($stateParams.id, $scope.selected_devices).then(function(){
                        $scope.$parent.deviceParams.reload();
                        $scope.$parent.selected_devices = [];
                        $uibModalInstance.dismiss('cancel');
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

    $scope.removeAccess = function()
    {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$client_access', function($state, $scope, $uibModalInstance, $client_access) {

                $scope.title = 'Deseja realmente remover este(s) acessos?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                $scope.confirm = function() {
                    _.each( $scope.selected_access, function(access){
                        $client_access.delete({id: access}).$promise.then(function(response){
                            $uibModalInstance.dismiss('cancel');
                            $scope.$parent.selected_access = [];
                            $scope.accessParams.reload();
                        });
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.removeMangers = function()
    {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$client_manager', function($state, $scope, $uibModalInstance, $client_manager) {

                $scope.title = 'Deseja realmente remover este(s) adminisrador?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                $scope.confirm = function() {
                    _.each( $scope.selected_manager, function(manager){
                        $client_manager.delete({id: manager}).$promise.then(function(response){
                            $uibModalInstance.dismiss('cancel');
                            $scope.$parent.selected_manager = [];
                            $scope.managersParams.reload();
                        });
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.checkDocument = function(data)
    {
        if( !data || data.length < 11 || data == $scope.originalDocument ){
            return;
        }
        $http.get(api+'client/checkDocument/'+data).then(function(response){
            $scope.documentError = false;
        }, function(error){
            $scope.documentError = true;
        });
    }

    $scope.block = function()
    {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$client_manager', function($state, $scope, $uibModalInstance, $client_manager) {

                $scope.title = 'Deseja realmente '+($scope.client.status ? 'bloquear' : 'desbloquear')+' este cliente?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                $scope.confirm = function() {
                    $client.block($stateParams.id).then(function(response){
                        $uibModalInstance.dismiss('cancel');
                        $scope.client.status = !$scope.client.status;
                    });
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    }

    $scope.notify = function()
    {
        $client.notify($stateParams.id, $scope.notification).then(function(response){
            ngToast.success({
                content: 'Notificação enviada',
                dismissOnTimeout: true,
                timeout: 3000
            });
        }, function(error){
            ngToast.danger({
                content: 'Erro!',
                dismissOnTimeout: true,
                timeout: 3000
            });
        });
    }

}]);

angular.module('client')
.controller('client.managers', ['$scope', '$http', '$auth', '$q', '$uibModal', '$uibModalInstance', '$client_manager', 'client_id',
    function($scope, $http, $auth, $q, $uibModal, $uibModalInstance, $client_manager, client_id) {
        $scope.title        = 'Gestor';
        $scope.cancel_text  = 'Cancelar';
        $scope.success_text = 'Concluído';
        $scope.newManager   = false;
        $scope.manager      = { name : "" , email : "", client_id : client_id};

        $scope.confirm = function()
        {
            var manager = angular.copy($scope.manager);
            $client_manager.save(manager).$promise.then(function(response){
                $uibModalInstance.close(response);
            }, function(error){
                if( error.data.message == 'manager_already_exists'){
                    $scope.duplicate = true;
                }
            });

        };

        $scope.cancel = function()
        {
            $uibModalInstance.dismiss('cancel');
        };
}]);

angular.module('client')
.controller('client.access', ['$scope', '$http', '$auth', '$q', '$uibModal', '$uibModalInstance', '$client_access', 'client_id',
    function($scope, $http, $auth, $q, $uibModal, $uibModalInstance, $client_access, client_id) {
        $scope.title        = 'Acessos';
        $scope.cancel_text  = 'Cancelar';
        $scope.success_text = 'Concluído';
        $scope.newManager   = false;
        $scope.access      = { description : "" , color : "", client_id : client_id };

        $scope.confirm = function()
        {
            var access = angular.copy($scope.access);
            $client_access.save(access).$promise.then(function(response){
                $uibModalInstance.close(response);
            }, function(error){
                console.log(error);
            });

        };

        $scope.cancel = function()
        {
            $uibModalInstance.dismiss('cancel');
        };
}]);

angular.module('client')
.controller('client.notify', ['$scope', '$http', '$auth', '$q', '$uibModal', '$uibModalInstance', '$client', 'client_id', 'ngToast', 'user',
    function($scope, $http, $auth, $q, $uibModal, $uibModalInstance, $client, client_id, ngToast, user) {
        $scope.title        = 'Notificação';
        $scope.cancel_text  = 'Cancelar';
        $scope.success_text = 'Concluído';

        $client.get({id : client_id}).$promise.then(function(response){
            $scope.client = response;
            $scope.notification.subject = response.name;
            $scope.accesses = response.accesses;
            $scope.access_id="";
        });

        $scope.notification = {
            subject : '',
            text    : '',
            to      : 'all',
            to_name : user ? user.name : 'Todos',
            user_id : user ? user.user_id : null,
            access_id:''
        }

        $scope.confirm = function()
        {   $scope.notification.access_id = $scope.access_id;
            $client.notify(client_id, $scope.notification).then(function(response){
                ngToast.success({
                    content: 'Notificação enviada',
                    dismissOnTimeout: true,
                    timeout: 3000
                });
            }, function(error){
                ngToast.danger({
                    content: 'Erro!',
                    dismissOnTimeout: true,
                    timeout: 3000
                });
            });
        };

        $scope.cancel = function()
        {
            $uibModalInstance.dismiss('cancel');
        };
}]);

/**
 * Factory
 */
angular.module('client').
factory("$client", [ '$resource', '$q', '$http',
function($resource,$q,$http) {
    var resource = $resource(api + 'client/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        }
    });
    
    resource.updateAvatar = function(id,formData){
        console.log("chamou update");
        var deferred = $q.defer();
        $http.post(api+'client/'+id+'/updateAvatar', formData, {
            headers: {'Content-Type': undefined },
              transformRequest: angular.identity
          }).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }
    resource.enableDevices = function(id, devices)
    {
        var deferred = $q.defer();
        $http.post(api+'client/'+id+'/devices', {devices:devices}).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.removeDevices = function(id, devices)
    {
        var deferred = $q.defer();
        $http.post(api+'client/'+id+'/remove_devices', {devices:devices}).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.getApartmentBuildings = function(id)
    {
        var deferred = $q.defer();
        $http.get(api+'client/'+id+'/apartment_buildings').then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.getUnits = function(id, block)
    {
        var deferred = $q.defer();
        $http.get(api+'client/'+id+'/block/'+block+'/units').then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.updateUnitAcesess = function(unit_id, accesses){
        var deferred = $q.defer();
        $http.post(api+'client/update/unit/accesses',{unit_id:unit_id, accesses:accesses}).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.updateBlockAcesess = function(block_id, accesses){
        var deferred = $q.defer();
        $http.post(api+'client/update/unit/accesses',{block_id:block_id, accesses:accesses}).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.getDevices = function(id)
    {
        var deferred = $q.defer();
        $http.get(api+'client/'+id+'/devices').then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.notify = function(id, params)
    {
        var deferred = $q.defer();
        $http.post(api+'client/'+id+'/notify', params).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.block = function(id)
    {
        var deferred = $q.defer();
        $http.post(api+'client/'+id+'/block').then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    return resource;
}]);

angular.module('client').
factory("$client_manager", [ '$resource', '$q', '$http',
function($resource,$q,$http) {
    var resource = $resource(api+'client_manager/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        }
    });

    resource.getAll = function(params){
        var deferred = $q.defer();
        $http.get(api+'client_manager',{params: params}).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    resource.getAllByClient = function(id){
        var deferred = $q.defer();
        $http.get(api+'client_manager',{params: {client_id: id}}).then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    return resource;
}]);
