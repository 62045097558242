angular.module('integrator', ['ui.router','confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap']);

angular.module('integrator').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

    $stateProvider
    .state('integrator', {
        url         : "/integrator",
        parent: 'app.admin',
        views: {
            'content':{
                templateUrl : server+"/views/m/integrator",
                controller  : 'integrator.list',
            }
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Integrador'
        }
    })

    .state('integrator_create', {
        url         : "/integrator/create",
        parent: 'app.admin',
        views: {
            'content':{
                templateUrl : server+"/views/m/integrator/create",
                controller  : 'integrator.create',
            }
        },
        ncyBreadcrumb: {
            parent: 'invite',
            label: 'Adicionar'
        }
    })

    .state('integrator_show', {
        url         : "/integrator/:id",
        parent: 'app.admin',
        views: {
            'content':{
                templateUrl : server+"/views/m/integrator/show",
                controller  : 'integrator.show',
            }
        },
        ncyBreadcrumb: {
            parent: 'integrator',
            label: '{{integrator.name}}'
        }
    })

}]);

/**
 * List Controller
 */
angular.module('integrator').
controller('integrator.list', ['$scope', '$http', '$auth', '$q', 'NgTableParams', '$integrator', function($scope, $http, $auth, $q, NgTableParams, $integrator) {

    $scope.tableParams = new NgTableParams({
        page: 1,
        count: 10
    }, {
        counts: [],
        filterDelay: 0,
        getData: function(params) {
            return $integrator.query(params.url()).$promise.then(function(data) {
                params.total(data.total);
                return data.data;
            });
        }
    });


}]);

/**
 * Create Controller
 */
angular.module('integrator').
controller('integrator.create', ['$scope', '$state', '$integrator', function($scope, $state, $integrator) {
    $scope.save = function() {
        $integrator.save($scope.integrator).$promise.then(function(data) {
            $state.go('integrator_show', {
                id: data.id
            });
        });
    }
}]);

/**
 * Show, Edit, Delete Controller
 */
angular.module('integrator').
controller('integrator.show', ['$state', '$scope', '$stateParams', '$uibModal', '$integrator', function($state, $scope, $stateParams, $uibModal, $integrator) {

    $scope.editing = false;
    $scope.showingKey = false;

    $scope.toggleEdit = function() {
        $scope.editing = !$scope.editing;
    }

    $integrator.get({
        id: $stateParams.id
    }).$promise.then(function(data) {
        $scope.integrator = data;
    }, function(error) {
        if (error.status == 404) {
            $state.go('notFound');
        }
    });

    $scope.showKey = function() {
        $integrator.getKeys($stateParams.id).then(function(response){
            $scope.keys = response;
            $scope.showingKey = true;
        });

    }

    $scope.confirmUpdate = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', '$integrator', function($scope, $uibModalInstance, $integrator) {

                $scope.title = 'Deseja atualizar?';
                $scope.cancel_text = 'Cancelar';
                $scope.success_text = 'Concluído';

                var integrator = $scope.$parent.integrator;

                $scope.confirm = function() {
                    $integrator.update({
                        id: integrator.id
                    }, integrator).$promise.then(function(data) {
                        $uibModalInstance.dismiss('cancel');
                        $scope.$parent.toggleEdit();
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

    $scope.confirmDelete = function() {
        var modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'confirm.modal.html',
            size: 'sm',
            scope: $scope,
            controller: ['$state', '$scope', '$uibModalInstance', '$integrator', function($state, $scope, $uibModalInstance, $integrator) {

                $scope.title = 'Deseja remover esse registro?';
                $scope.cancel_text = 'Não';
                $scope.success_text = 'Sim';

                var integrator = $scope.$parent.integrator;

                $scope.confirm = function() {
                    $integrator.delete({id:integrator.id}).$promise.then(function(data){
                        $uibModalInstance.dismiss('cancel');
                        $state.go('integrator');
                    })
                }

                $scope.cancel = function() {
                    $uibModalInstance.dismiss('cancel');
                };

            }]
        });
    };

}]);


/**
 * Factory
 */
angular.module('integrator').
factory("$integrator", ['$resource','$q', '$http', function($resource, $q, $http) {
    var resource = $resource(api + 'integrator/:id', null, {
        'query': {
            method: 'GET',
            isArray: false
        },
        'update': {
            method: 'PUT'
        }
    });

    resource.getKeys = function(id){
        var deferred = $q.defer();
        $http.get(api+'integrator/'+id+'/get_keys').then(function(response){
            deferred.resolve(response.data);
        }, function(response) {
            deferred.reject(response);
        });
        return deferred.promise;
    }

    return resource;
}]);

angular.module("confirm.modal.html", []).run(["$templateCache", function($templateCache) {
    $templateCache.put('confirm.modal.html',
        '<div class="modal-body">' +
        ' {{title}}' +
        '</div>' +
        '<div class="modal-footer">' +
        '    <button class="btn btn-link btn-info" type="button" ng-click="cancel()">{{cancel_text}}</button>' +
        '    <button class="btn btn-outline btn-success" type="button" ng-click="confirm()">{{success_text}}</button>' +
        '</div>'
    );
}]);
