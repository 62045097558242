angular.module('report', ['ui.router','confirm.modal.html', 'ngResource', 'ngTable', 'ui.bootstrap']);

angular.module('report').config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

    $stateProvider
    .state('report', {
        url         : "/dashboard",
        parent: 'app.user',
        views : {
            'content': {
                templateUrl : server+"/views/m/report/dashboard",
                controller  : 'dashboard'
            }
        },
        ncyBreadcrumb: {
            parent: 'home',
            label: 'Relatório de Ordens de Serviço'
        }
    })

}]);

/**
 * List Controller
 */
angular.module('report').
controller('dashboard', ['$scope', '$http', '$q', 'NgTableParams', '$window', 'ngToast',
function($scope, $http, $q, NgTableParams,$window, ngToast) {

    $scope.filter = {
      status: 'finished',
      start_date: moment().startOf('month').toDate(),
      end_date: moment()
    };

    $scope.dates = {
      start_date: moment().startOf('month').toDate(),
      end_date: moment().toDate()
    }

    $scope.selectedAccess = [];

    $scope.accessSettings = {
    };

    $scope.start_date = { opened: false };
    $scope.end_date = { opened: false };

    $scope.openDate = function(obj){
      obj.opened = true;
    }

    $http.get(api+'user/device?owner=true&count=300').then((response) => {
      $scope.accessess = [];
      for (var i = 0; i < response.data.length; i++) {
        var data = response.data[i];
        $scope.accessess.push({id:data["hash"], label: data["nickname"], client: data["device"]["client"]["name"]});
        $scope.selectedAccess = [$scope.accessess[0]];
      }

      $scope.loadDashboard();
    });

    $scope.loadDashboard = function(){
      $scope.filter.start_date = moment($scope.dates.start_date).format('YYYY-MM-DD HH:mm:ss');
      $scope.filter.end_date   = moment($scope.dates.end_date).format('YYYY-MM-DD HH:mm:ss');
      $scope.filter.user_device = _.pluck($scope.selectedAccess,'id').join(",");

      $http.get(api+'report/dashboard', { params: $scope.filter }).then(function(response){
        $scope.dashboard = response.data;
        console.log(response.data);
      });
    }



}]);
